.status {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #F59F00;
    margin-right: 16px;

    .svg-icon {
        display: block;
        margin-right: 8px;
        margin-top: -2px;

        use {
            fill: transparent;
            stroke:  #F59F00;
        }
    }
}