.faq {

    &__item {
        padding: 36px;
        box-shadow: 0px 11px 30px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin-bottom: 42px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 17px;
        line-height: 160%;
        letter-spacing: -0.015em;
        margin-bottom: 12px;
    }

    &__text {
        font-size: 17px;
        line-height: 160%;
    }
}

@media(max-width: 1279px) {
    .faq {
        &__name {
            font-size: 14px;
            line-height: 160%;
        }

        &__text {
            font-size: 14px;
            line-height: 160%;
        }
    }
}

@media(max-width: 640px) {
    .faq {
        &__item {
            padding: 0;
            box-shadow: none;
            padding-bottom: 19px;
            border-bottom: 1px solid #F3F3F3;

            &:last-child {
                padding-bottom: 0;
                border: 0;
                margin: 0;
            }
        }

        &__name {
            margin-bottom: 19px;
        }
    }
}