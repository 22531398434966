.articles {
    margin: 0 -28px;

    &__item {
        display: inline-flex;
        flex-direction: column;
        overflow: hidden;
        text-decoration: none;

        &:hover {
            .articles {
                &__btn {
                    .btn {
                        background-color: @green;
                    }
                }
            }
        }
    }

    &__img {
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 16px;
       

        img {
            display: block;
            width: 100%;
        }
    }

    &__container {
        width: 33.3%;
        padding: 0 28px 30px;
    }

    &__time {
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        color: @green;
        margin-bottom: 12px;
    }

    &__text {
        font-size: 15px;
        line-height: 160%;
        letter-spacing: -0.015em;
        color: #000000;
    }

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 12px;
        transition: color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .articles {
        &__item {
            &:hover {
                .articles {
                    &__name {
                        color: @green;
                    }
                }
            }
        }
    }
}

@media(max-width: 1280px) {
    .articles {
        margin: 0 -20px;

        &__container {
            width: 50%;
            padding: 0 20px 40px;
        }
    }
}

@media(max-width: 960px) {
    .articles {
        margin: 0 -15px;

        &__container {
            padding: 0 15px 50px;
        }

        &__time {
            margin-bottom: 10px;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }

        &__name {
            font-size: 14px;
            line-height: 160%;
            margin-bottom: 10px;
        }
    }
}


@media(max-width: 640px) {
    .articles {
        &__container {
            width: 100%;
        }
    }
}