.panel {
  /*  position: fixed;
    will-change: transform;*/
/*
    &--one {
        height: 3290px;
        width: 100%;
    }

    &--two {
        height: 2122px;
        width: 100%;
    }*/
}

.main-block {
    &__wrap {
        position: relative;
       // padding-bottom: 810px;
    }
}

.panel {
    &.is-active {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        
    }
}
/*
.panel {


    .main-block__wrap {
        &:first {

        }

        &:last-child {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            opacity: 0;
        }
    }

    &.is-active {
        .main-block__wrap {
            &:first-child {
                pointer-events: none;
                opacity: 0;
            }
    
            &:last-child {
                pointer-events: auto;
                opacity: 1;
            }
        }
    }

}*/