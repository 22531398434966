.subscription {
    display: flex;
    align-items: center;
    height: 42px;
    position: relative;

    &--mobile {
        display: none;
    }

    &__container {
        display: flex;
        align-items: center;
        height: 100%;
        transition: opacity 0.3s ease;
    }

    &__ok {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #F59F00;
        border-radius: 30px;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #ffff;
        padding: 12px 20px 12px 10px;
        transition: opacity 0.3s ease;
        opacity: 0;
        pointer-events: none;

        .svg-icon {
            margin-right: 10px;
            flex: none;

            use {
                fill: #fff;
            }
        }
    }

    input {
        font-family: Montserrat;
        height: 100%;
        width: 347px;
        padding-right: 145px;
        padding-left: 22px;
        background-color: #101010;
        border-radius: 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        border: 0;
        color: #fff;

        &::-webkit-input-placeholder  {color: @grey-2;}
        &::-moz-placeholder         {color: @grey-2;}
        &:-moz-placeholder          {color: @grey-2;}
        &:-ms-input-placeholder      {color: @grey-2;}
    }

    &__btn {
        cursor: pointer;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        height: 100%;
        background-color: @green;
        box-shadow: 0px 7px 15px rgba(0, 167, 89, 0.25);
        border: 0;
        border-radius: 30px;
        letter-spacing: .05em;
        text-transform: uppercase;
        color: #fff;
        padding: 0 29px;
        margin-left: -131px;
        transition: box-shadow 0.3s ease;
    }

    &.is-ok {
        .subscription {
            &__container {
                opacity: 0;
                pointer-events: none;
            }

            &__ok {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}

@media(min-width: 960px) {
    .subscription {
        &__btn {
            &:hover {
                box-shadow: 0px 4px 31px rgba(0, 167, 89, 0.8);
            }
        }
    }
}

@media(max-width: 1279px) {
    .subscription {
        &__button {
            width: 42px;
            height: 42px;
            background-color: @green;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0 2px 2px;

            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }

        &--mobile {
            background: rgba(15,25,50,.9);
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            left: 0;
            right: 0;
            top: 54px;
            bottom: 0;
            height: auto;
            z-index: 18;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            &.is-active {
                opacity: 1;
                pointer-events: auto;
            }

            input {
                width: 100%;
                height: 48px;
                background-color: #fff;
                text-align: center;
                padding: 0 15px;
                margin-bottom: 18px;
                color: #000;
            }


            .subscription {

                &__result {
                    position: absolute;
                    color: #fff;
                    top: 95px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    font-size: 13px;
                    bottom: initial;
                    text-align: center;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

                &__close {
                    top: 21px;
                    right: 23px; 
                    position: absolute;
                }

                &__ok {
                    height: auto;
                    top: initial;
                    right: 0;
                    margin: 0 auto;
                    width: 427px;
                    left: 0;
                }

                &__btn {
                    width: 100%;
                    height: 48px;
                    margin: 0;
                }

                &__icon {
                    margin-bottom: 53px;
                }
                
                &__container {
                    position: relative;
                    flex-direction: column;
                    width: 427px;
                    margin: 0 auto;
                    height: auto;
                }
            }
        }
    }
}

@media(max-width: 960px) {  
    .subscription {
        &__button {
            width: 38px;
            height: 38px;
            padding: 10px;
        }
    }
}


@media(max-width: 480px) {  
    .subscription {
        &--mobile {
            padding: 26px;

            .subscription {
                &__container {
                    width: 100%;
                }

                &__icon {
                    margin-bottom: 32px;
                }

                &__ok {
                    left: 26px;
                    right: 26px;
                    width: auto;
                }
            }
        }

    }
}