.planet {
    margin-top: 63px;

    &__control {
        display: flex;
        justify-content: center;
    }

    &__link {
        cursor: pointer;
        font-size: 17px;
        line-height: 160%;
        color: #FFFFFF;
        background-color: #15213E;
        border-radius: 6px;
        padding: 5px 17px 6px;
        margin: 0 8px;
        transition: color 0.3s, background-color 0.3s ease;

        &.is-active {
            background-color: @green;
            color: #fff;
        }
    }
}

@media(min-width: 960px) {
    .planet {
        &__link {
            &:hover {
                color: #00B25F;
            }

            &.is-active {
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}

@media(max-width: 1279px) { 
    .planet {
        margin-top: 10px;

        &__link {
            font-size: 14px;
            line-height: 160%;
            margin: 0 7px;
        }
    }
}

@media(max-width: 640px) { 
    .planet {
        &__control {
            display: none;
        }
    }
}