.form-big {
    background-color: @green;
    padding: 100px 0 76px;
    overflow: hidden;
    position: relative;

    &__wrap {
        padding-right: 103px;
        z-index: 1;
    }

    &__block {
        transition: opacity 0.3s ease;
    }

    &__back {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 645px;
        top: -38px;
        transition: opacity 0.3s ease;

        img {
            max-width: 100%;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 33px;
        line-height: 132%;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 18px;
    }

    &__agree {
        font-size: 13px;
        line-height: 150%;
        color: #fff;

        a {
            color: #fff;
        }
    }

    &__btn {
        margin-bottom: 18px;

        .btn {
            border-radius: 30px;
            padding: 12px 24px;
            font-size: 13px;
            line-height: 16px;
            box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.19);
        }
    }

    &__field {
        margin-bottom: 18px;

        input {
            outline: 0;
            width: 100%;
            border-radius: 6px;
            height: 48px;
            padding: 0 20px;
            border: 1px solid transparent;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: #000000;

            &::-webkit-input-placeholder {color: #8A8A8A; transition: color 0.3s ease;}
            &::-moz-placeholder          {color: #8A8A8A; transition: color 0.3s ease;}
            &:-moz-placeholder          {color: #8A8A8A; transition: color 0.3s ease;}
            &:-ms-input-placeholder     {color: #8A8A8A; transition: color 0.3s ease;}
    
            &.is-error {
                color: #EB3838;
                background-color: #FCE9E9;
                border: 1px solid #EB3838;
    
                &::-webkit-input-placeholder {color: #EB3838;}
                &::-moz-placeholder          {color: #EB3838;}
                &:-moz-placeholder       {color: #EB3838;}
                &:-ms-input-placeholder    {color: #EB3838;}
            }
    
        }

        textarea {
            background: #FFFFFF;
            border-radius: 6px;
            height: 175px;
            width: 100%;
            border: 1px solid transparent;
            padding: 10px 20px;
            color: #000000;

            &::-webkit-input-placeholder {color: #8A8A8A; transition: color 0.3s ease;}
            &::-moz-placeholder          {color: #8A8A8A; transition: color 0.3s ease;}
            &:-moz-placeholder          {color: #8A8A8A; transition: color 0.3s ease;}
            &:-ms-input-placeholder     {color: #8A8A8A; transition: color 0.3s ease;}
    
            &.is-error {
                color: #EB3838;
                background-color: #FCE9E9;
                border: 1px solid #EB3838;
    
                &::-webkit-input-placeholder {color: #EB3838;}
                &::-moz-placeholder          {color: #EB3838;}
                &:-moz-placeholder       {color: #EB3838;}
                &:-ms-input-placeholder    {color: #EB3838;}
            }
    
        }
    }

    &__text {
        font-weight: 500;
        font-size: 19px;
        line-height: 150%;
        color: #fff;
        letter-spacing: -0.015em;
    }

    &__form {
        width: 378px;
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__body {
        max-width: 502px;
        margin-top: -18px;
    }

    &--contact {
        .form-big {

            &__back {
                width: 717px;
                top: -15px;
            }

            &__title {
                text-align: center;
                margin-bottom: 8px;
            }

            &__field {
                margin-bottom: 20px;

                input {
                    font-size: 15px;
                    line-height: 160%;
                    height: 45px;
                }
            }

            &__agree {
                max-width: 526px;
                margin: 0 auto;
                text-align: center;
            
                br {
                    display: none;
                }
            }

            &__btn {
                display: flex;
                justify-content: center;
                margin-top: 11px;
                margin-bottom: 11px;

                .btn {
                    width: 258px;
                }
            }

            &__form {
                width: 72%;
                margin: 46px auto 0;
                display: flex;
            }

            &__col {
                margin: 0 15px;
                width: calc(~"47% - 30px");

                &:nth-child(2) {
                    width: calc(~"53% - 30px");
                }
            }

            &__text {
                text-align: center;
                font-size: 15px;
                line-height: 160%;
            }
        }
    }

    &.is-ok {
        .form-big {
            &__block {
                opacity: 0;
                pointer-events: none;
            }

            &__back {
                opacity: 0;
                pointer-events: none;
            }
        }

        .form-ok {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

@media(min-width: 960px) {
    .form-big {
        &__btn {
            .btn {
                &:hover {
                    box-shadow: 0 11px 25px rgba(0, 0, 0 , 19%);
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .form-big {
        padding-top: 81px;
        padding-bottom: 58px;

        &__title {
            font-size: 25px;
            line-height: 132%;
        }

        &__back {
            top: -66px;
        }

        &__text {
            font-size: 16px;
            line-height: 150%;
        }

        &__form {
            width: 332px;
        }

        &__field {
            width: 272px;

            input {
                height: 45px;
                font-size: 16px;
                line-height: 150%;
            }
        }

        &__wrap {
            padding-right: 0;
        }

        &__agree {
            font-size: 12px;
            line-height: 150%;
        }

        &__body {
            max-width: 50%;
            padding-right: 30px;
        }

        &--contact {
            .form-big {
                &__title {
                    font-size: 21px;
                    line-height: 132%;
                }

                &__form {
                    width: 87%;
                    margin: 24px auto 0;
                }

                &__btn {
                    .btn {
                        width: auto;
                    }
                }

                &__field {
                    width: auto;
                }

                &__col {
                    margin: 0 10px;
                    width: calc(~"50% - 20px");

                    &:nth-child(2) {
                        width: calc(~"50% - 20px");
                    }
                }

                &__back {
                    width: 645px;
                    top: 0;
                }

                &__agree {
                    max-width: 370px;
                    font-size: 13px;
                    line-height: 150%;
                }

                &__text {
                    max-width: 590px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .form-big {
        padding-top: 70px;
        padding-bottom: 70px;
    
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
            width: 500px;
        }

        &__title {
            font-size: 19px;
            line-height: 132%;
        }

        &__form {
            width: 378px;
        }

        &__back {
            width: 755px;
            left: inherit;
            right: -351px;
            top: -57px;
        }

        &__field {
            width: 100%;

            textarea {
                height: 126px;
            }

            input {
                height: 42px;
                font-size: 14px;
                line-height: 150%;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 160%;
            font-weight: normal;
        }

        &__body {
            max-width: initial;
            padding-right: 0;
            margin-bottom: 36px;
            margin-top: 0;
        }

        &--contact {
            padding-top: 40px;
            padding-bottom: 36px;


            .form-big {

                &__back {
                    width: 755px;
                }
             
                &__title {
                    font-size: 16px;
                    line-height: 132%;
                    text-align: left;
                    margin-bottom: 20px;
                    text-align: center;
                }

                &__btn {
                    justify-content: center;
                    margin-bottom: 18px;
                    margin-top: -7px;
                }

                &__agree {
                    max-width: 500px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    
                    br {
                        display: block;
                    }
                }

                &__field {
                    margin-bottom: 18px;

                    input {
                        height: 42px;
                    }
                }

                &__form {
                    width: 378px;
                    margin: 35px auto 0;
                    display: block;
                }

                &__col {
                    width: 100%;
                    margin: 0;

                    &:nth-child(2) {
                        width: 100%;
                        margin: 0;
                    }
                }
                

                &__text {
                    font-size: 14px;
                    line-height: 160%;
                    max-width: initial;
                    text-align: center;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .form-big {
        padding-top: 58px;
        padding-bottom: 57px;

        &__body {
            margin-bottom: 24px;
        }

        &__wrap {
            width: 100%;    
        }

        &__form {
            width: 100%;
        }

        &--contact {
            padding-top: 31px;
            padding-bottom: 28px;

            .form-big {
                &__form {
                    width: 100%;
                    margin-top: 21px;
                }
            }
        }
    }
}


@media(max-width: 480px) {
    .form-big {
        padding-top: 40px;
        padding-bottom: 40px;

        &__body {
            margin-bottom: 30px;
        }

    }
}