.form {
    &__field {
        margin-bottom: 20px;
    }

    &__input {
        height: 45px;
        background-color: #F3F3F3;
        border-radius: 6px;
        font-size: 15px;
        line-height: 160%;
        letter-spacing: -0.015em;
        padding: 0 20px;
        outline: 0;
        border: 0;
        width: 100%;
        border: 1px solid transparent;
        transition: border-color 0.3s, color 0.3s, background-color 0.3s ease;

        &::-webkit-input-placeholder {color: #8A8A8A; transition: color 0.3s ease;}
        &::-moz-placeholder          {color: #8A8A8A; transition: color 0.3s ease;}
        &:-moz-placeholder          {color: #8A8A8A; transition: color 0.3s ease;}
        &:-ms-input-placeholder     {color: #8A8A8A; transition: color 0.3s ease;}

        &.is-error {
            color: #EB3838;
            background-color: #FCE9E9;
            border: 1px solid #EB3838;

            &::-webkit-input-placeholder {color: #EB3838;}
            &::-moz-placeholder          {color: #EB3838;}
            &:-moz-placeholder       {color: #EB3838;}
            &:-ms-input-placeholder    {color: #EB3838;}
        }


    }

    &__textarea {
        height: 45px;
        background-color: #F3F3F3;
        border-radius: 6px;
        font-size: 15px;
        line-height: 160%;
        letter-spacing: -0.015em;
        padding: 9px 20px;
        outline: 0;
        border: 0;
        width: 100%;
        transition: height 0.3s ease;
        border: 1px solid transparent;
    }

    &__agree {
        margin-top: 20px;
        font-size: 14px;
        line-height: 160%;
        color: #8A8A8A;

        span {
            transition: color 0.3s ease;
        }

        a {
            color: @green;
            text-decoration: none;
            white-space: nowrap;
        }
    }

    &__btn {
        .btn {
            width: 100%;
        }
    }

    &.is-error {
        .form {
            &__agree {
                span {
                    color: #EB3838;
                }
            }
        }
    }
}


.form-ok {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding: 25px;
    color: @green;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &__icon {
        margin-bottom: 42px;
    }

    &__title {
        font-weight: bold;
        font-size: 33px;
        line-height: 132%;
        text-transform: uppercase;
        margin-bottom: 9px;
    }

    &__text {
        font-weight: 500;
        font-size: 17px;
        line-height: 160%;
    }

    &--white {
        color: #fff;
    }
}

@media(max-width: 640px) {
    .form {
        &__agree {
            font-size: 13px;
            line-height: 160%;
        }
    }

    .form-ok {
        &__title {
            font-size: 23px;
        }

        &__text {
            font-size: 13px;
            line-height: 140%;

            br {
                display: none;
            }
        }

        &__icon {
            margin-bottom: 20px;

            svg {
                width: 60px;
                height: auto;
            }
        }
    }
}
