.social {
    display: flex;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 13px;
        background-color: #343434;
        overflow: hidden;

        &--ins {
            &::before {
                content:" ";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(45deg, #F9D408 15.87%, #F91902 48.08%, #7B35AB 80.29%);
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        &--face {
            &::before {
                content:" ";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background: #3C589A;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

        .svg-icon {
            position: relative;
            z-index: 1;

            use {
                fill: #fff;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

@media(min-width: 960px) { 
    .social {
        &__item {
            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}

@media(max-width: 1279px) { 
    .social {
        margin-right: 10px;

        &__item {
            margin-right: 10px;
        }
    }
}

@media(max-width: 960px) {  
    .social {
        &__item {
            width: 38px;
            height: 38px;
            padding: 8px;
        }
    }
}