.lang {
    display: inline-flex;
    align-items: center;
    margin-left: 40px;
    background: rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 2px;

    &__item {
        display: inline-flex;
        text-decoration: none;
        padding: 0 8px;
        align-items: center;
        height: 20px;
        font-weight: 500;
        font-size: 11px;
        line-height: 132%;
        color: @green;
        border-radius: 12px;
        transition: background-color 0.3s, color 0.3s ease;

        &:last-child {
            margin-right: 0;
        }

        &.is-active {
            color: #fff;
            background-color: @green;
        }
    }
}

@media(max-width: 1440px) {
    .lang {
        margin-left: 52px;
    }
}

@media(max-width: 1279px) {
    .lang {
        margin-left: 53px;
    }
}

@media(max-width: 960px) {
    .lang {
        margin-left: 36px;
    }
}

@media(max-width: 640px) {
    .lang {
        &__item {
            padding: 0 6px;
            height: 15px;
            font-size: 10px;
            line-height: 132%;
        }
    }
}

@media(max-width: 480px) {
    .lang {
        margin-left: 20px;
    }
}
