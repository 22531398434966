.accessories {
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px -18px;

    &__item {
        display: flex;
        align-items: center;
        width: calc(~"50% - 30px");
        padding-right: 15px;
        margin: 0 15px 18px;
        background-color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.015em;
        text-decoration: none;
        color: #0F1932;
        border-radius: 6px;
    }

    &__img {
        width: 84px;
        height: 84px;
        flex: none;
        margin-right: 18px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

