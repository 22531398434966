.menu-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 114px;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: box-shadow 0.3s ease;
        margin-right: 27px;
        padding-left: 2px;
        padding-top: 1px;

        .svg-icon {
            transition: transform 0.3s ease;

            use {
                fill: transparent;
                stroke: @green;
            }
        }
    }

    a {
        display: inline-flex;
        text-decoration: none;
        align-items: center;
        font-weight: bold;
        font-size: 17px;
        line-height: 160%;
        color: @green;
        letter-spacing: -0.015em;
        margin-bottom: 24px;

        &.is-active {
            .menu-left {
                &__icon {
                    box-shadow: 0px 3px 5px rgba(0, 167, 89, 0.31);

                    .svg-icon {
                        transform: translateX(0) !important;
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(min-width: 960px) {
    .menu-left {
        a {
            &:hover {
                .menu-left {
                    &__icon {
                        .svg-icon {
                            transform: translateX(5px);
                        }
                    }
                }
            }
        }
    }
}