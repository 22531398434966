.share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: #fff;
    top: 0;
    padding-top: 77px;
    transform: translateX(100%);
    transition: transform 0.3s ease;

    &.is-open {
        transform: translateX(0);

        &::before {
            opacity: 1;
        }
    }

    &__close {
        top: 120px;
        position: absolute;
        cursor: pointer;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 80px;
        height: 80px;
        text-decoration: none;

        span {
            margin-top: 7px;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
            color: @green;
            text-align: center;
        }
    }

    &::before {
        content: " ";
        width: 189px;
        pointer-events: none;
        position: absolute;
        left: -189px;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
        background: linear-gradient(270deg,rgba(0,0,0,.04),transparent);
    }
}