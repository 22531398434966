.search {
    display: flex;
    align-items: center;
    position: absolute;
    background-color: @green;
    height: 100%;
    right: -815px;
    top: 0;
    transition: right 0.3s ease;

    &.is-open {
        right: 0;
    }

    &__body {
        width: 740px;
        
        input[type='text'] {
            font-family: Montserrat;
            color: #fff;
            font-weight: 500;
            font-size: 18px;
            line-height: 132%;
            letter-spacing: 0.03em;
            background-color: transparent;
            border: 0;

            &::-webkit-input-placeholder {color:fade(#fff, 50);font-family: Montserrat;}
            &::-moz-placeholder          {color:fade(#fff, 50);font-family: Montserrat;}
            &:-moz-placeholder           {color:fade(#fff, 50);font-family: Montserrat;}
            &:-ms-input-placeholder      {color:fade(#fff, 50);font-family: Montserrat;}
        }
    }

    &__close {
        width: 78px;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &__button {
        margin-right: 33px;
       

        .svg-icon {
            display: block;

            use {
                fill: #fff;
            }
        }
    }
}

@media(max-width: 1440px) {
    .search {
        margin-left: 18px;
    }
}

@media(max-width: 960px) {
    .search {
        display: none;
    }
}

.footer {
    overflow: hidden;
}

.wrapper__container {
    overflow: hidden;  
}