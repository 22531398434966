.popup-schema {
    position: fixed;
    padding-top: 156px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 60;
    opacity: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(15, 25, 50, 0.5), rgba(15, 25, 50, 0.5));

    .mCSB_scrollTools .mCSB_draggerContainer {
        top: 5px;
        bottom: 5px;
    }

    .mCSB_scrollTools .mCSB_dragger {
       background-color: transparent;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
        width: 7px;
        background-color: transparent;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: #00a759;
        opacity: 0.5;
    }

    .mCSB_scrollTools {
        width: 7px;
        right: 10px;
    }

    &.is-open {
        opacity: 1;
        pointer-events: auto;
    }

    &__wrap {
        width: 870px;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
      //  overflow-y: auto;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 21px 65px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(19px);
        border-radius: 10px;
        margin-bottom: -50px;
        position: relative;
        z-index: 1;
    }

    &__main {
        padding: 50px 42px 58px;
        border-radius: 18px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        background-color: #fff;
    }

    &__body {
        position: relative;
        z-index: 2;
        background-color: #fff;
        border-radius: 12px;
        overflow: hidden;
        overflow-y: auto;
    }

    &__dop {
        background-color: #f3f3f3;
        padding: 96px 42px 51px;
        margin-top: -51px;
        z-index: 1;
        position: relative;

        h5 {
            margin: 0 0 34px;
        }
    }

    &__close {
        cursor: pointer;

        .svg-icon {
            display: block;
            use {
                fill: #fff;
            }
        }
    }

    .control {
        margin-bottom: 0;

        &__img {
            width: 96px;
            height: 96px;
            flex: none;
            margin-right: 36px;
        }

        &__btn {
            flex: none;

            .btn {
                padding: 8px 13px 6px;
                font-size: 10px;
                line-height: 12px;
            }
        }

        &__body {
            padding-right: 36px;
        }

        &__name {
            font-size: 17px;
            line-height: 140%;
            margin-bottom: 6px
        }

        &__item {
            margin-bottom: 48px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__text {
            font-size: 14px;
            line-height: 160%;
        }
    }

    &--dev {
        .popup-schema {
            &__wrap {
                width: 672px;
            }
        }
    }
}

@media(min-width: 960px) {
    .popup-schema {
        padding-top: 0;
        &__close {
            &:hover {
                .svg-icon {
                    use {
                        fill: @green;
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .popup-schema {
        &__wrap {
            max-width: 100%;
        }
    }
}

@media(max-width: 768px) {
    .popup-schema {
        .accessories {
            &__item {
                width: calc(~"100% - 30px");
            }
        }
    }
}

@media(max-width: 640px) {
    .popup-schema {
        &__main {
            padding: 40px 25px 40px;
        }
    }
}

