.tag {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    &__top {
        display: none;
    }

    &__list {
        margin-left: -6px;
        margin-right: -6px;
    }

    &__item {
        display: inline-flex;
        text-decoration: none;
        padding: 3px 17px;
        background-color: @grey-1;
        color: @grey-3;
        border-radius: 20px;
        margin: 0 6px 12px;
        font-size: 15px;
        line-height: 160%;
        letter-spacing: -0.015em;
        transition: background-color 0.3s, color 0.3s ease;

        &.is-active {
            background-color: @green;
            color: #fff;
        }
    }
}

@media(min-width: 960px) {
    .tag {
        &__item {
            &:hover {
                color: @green;
            }
        }
    }
}

@media(max-width: 1279px) {
    .tag {
        margin: 0 0 63px;

        &__list {
            margin-left: -5px;
            margin-right: -5px;   
        }

        &__item {
            font-size: 13px;
            line-height: 160%;
            padding: 3px 17px 4px;
            margin: 0 5px 8px;
        }
    }
}

@media(max-width: 960px) {
    .tag {
        margin-bottom: 40px;
    }
}


@media(max-width: 640px) {
    .tag {
        position: relative;

        &__top {
            display: flex;
            align-items: center;
            position: relative;
            height: 43px;
            background: #f3f3f3;
            border-radius: 30px;
            width: 100%;
            padding: 0 40px 0 20px;
            transition: border-radius 0.3s ease;

            &.is-active {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                .tag {
                    &__control {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &__control {
            width: 43px;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 10px;
            top: 0;
            transition: transform 0.3s ease;

            svg {
                path {
                    stroke: #8A8A8A;
                    transition: stroke 0.3s ease;
                }
            }
        }

        &__item {
            margin: 0;
            width: 100%;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: -.015em;
            padding: 10px 17px;
            border-radius: 0;
        }

        &__list {
            display: none;
            position: absolute;
            top: 43px;
            right: 0;
            left: 0;
            background-color:  #f3f3f3;
            flex-direction: column;
            margin: 0;
            overflow: hidden;
            z-index: 5;
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        &__title {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: -0.015em;
            color: #8A8A8A;
        }
    }
}