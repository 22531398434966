.found {
    height: calc(~"100vh - 78px");
    background-image: url(../images/common/404.jpg);
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 80px;
    text-align: center;

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #fff;
        margin-bottom: 28px;
    }
}