.info-bottom {
    align-items: flex-end;
    color: @grey-2;
    font-weight: 500;
    font-size: 14px;
    line-height: 132%;
    letter-spacing: 0.03em;
    display: flex;
    flex-direction: column;
    margin-right: 44px;

    a {
        color: #fff;
        display: inline-flex;
        text-decoration: none;
        font-weight: 600;
        font-size: 19px;
        line-height: 132%;
        letter-spacing: 0.02em;
    }
}

@media(max-width: 1279px) { 
    .info-bottom {
        margin-right: 0;
        font-size: 12px;
        line-height: 132%;

        a {
            font-size: 16px;
            line-height: 132%;
        }
    }
}

@media(max-width: 960px) { 
    .info-bottom {
        font-size: 10px;
        line-height: 132%;

        a {
            font-size: 14px;
            line-height: 132%;
        }
    }
}


@media(max-width: 480px) { 
    .info-bottom {
        align-items: center;
        margin-bottom: 23px;
    }
}
