.dev {
    text-align: center;

    &__name {
        font-weight: bold;
        font-size: 17px;
        line-height: 140%;
        text-decoration: none;
        color: #0F1932;
        text-transform: uppercase;
        margin-bottom: 6px;
        display: inline-flex;
    }

    &__status {
        margin-bottom: 6px;
    }

    &__text {
        margin-bottom: 40px;
    }
}