.link {
    &__item {
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #8A8A8A;
        text-decoration: none;
        margin-bottom: 31px;
        transition: color 0.3s ease;
        
        &:last-child {
            margin-bottom: 0;
        }

        .svg-icon {
            use {
                fill: #8A8A8A;
            }
        }

        &:hover {
            color: @green;

            .svg-icon {
                use {
                    fill: @green;
                }
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
}