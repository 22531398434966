.product {
    margin-bottom: -6px;

    &__name {
        display: inline-flex;
        color: initial;
        font-weight: bold;
        font-size: 21px;
        line-height: 132%;
        text-transform: uppercase;
        margin-bottom: 24px;
        text-decoration: none;
        transition: color 0.3s ease;
    }

    &__body {
        max-width: calc(~"100% - 200px");
        padding-right: 69px;
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        width: 200px;

        img {
            max-width: 100%;
        }
    }

    &__btn {
        margin-top: 24px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        z-index: 1;
        color: inherit;
        padding: 42px 55px 42px 48px;
        background-color: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 6px;
        transition: box-shadow 0.3s, z-index 0.3s ease;

        .btn {
            &--green {
                &:hover {
                    color: #fff;
                    background: #00a759;
                    box-shadow: none;
                }

                &:active {
                    color: #fff;
                    background: #00a759;
                    box-shadow: none;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .product {
        &__item {
            &:hover {
                z-index: 2;
                box-shadow: 0px 4px 66px rgba(0, 167, 89, 0.3);

                .product {
                    &__name {
                        color: @green;
                    }
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .product {
        &__container {
            width: 100%;
            margin: 0 auto;
        }

        &__text {
            font-size: 14px;
            line-height: 160%;
        }

        &__name {
            font-size: 17px;
            line-height: 132%;
        }
    }
}

@media(max-width: 960px) {
    .product {
        margin-left: -30px;
        margin-right: -30px;

        &__item {
            padding: 30px;
        }

        &__body {
            max-width: calc(~"100% - 150px");
            padding-right: 30px;
        }

        &__btn {
            margin-top: 20px;
        }

        &__img {
            width: 150px;
        }

        &__name {
            margin-bottom: 20px;
        }
    }
}

@media(max-width: 640px) {
    .product {
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
        width: 85.6%;

        &__item {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 0;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 50px;
            position: relative;

            &::before {
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: #F3F3F3;
                border-radius: 4px;
            }

            &:last-child {
                padding-bottom: 0;

                &::before {
                    display: none;
                }
            }
        }

        &__name {
            margin-bottom: 18px;
        }

        &__img {
            order: 1;
            margin-bottom: 20px;
        }

        &__body {
            order: 2;
            max-width: 100%;
            padding: 0;
        
        }
    }
}

@media(max-width: 480px) {
    .product {
        width: 280px;
    }
}