.map-schema {
    display: flex;
    justify-content: center;
    position: relative;

    &__container {
        position: relative;
        width: 1706px;
        margin: 0 auto;
    }

   

    &__popup {
        width: 400px;
        position: absolute;
        bottom: 100%;
        left: -185px;
        padding-bottom: 32px;
        right: 0;
        margin: 0 auto;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &-close {
            cursor: pointer;
    
            .svg-icon {
                display: block;
                use {
                    fill: #fff;
                }
            }
        }

        &-top {
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(19px);
            padding: 12px 24px;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            padding-bottom: 44px;
            margin-bottom: -32px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-btn {
            .btn {
                width: 100%;
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;
                padding: 13px 17px;
                border-radius: 30px;
            }
        }

        &-info {
            margin-bottom: 20px;

            div {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                color: @green;
                text-decoration: none;
            }
        }

        &-body {
            background-color: #fff;
            border-radius: 16px;
            padding: 25px;
            font-size: 15px;
            line-height: 160%;
            letter-spacing: -0.015em;
            color: #000000;
            position: relative;
            z-index: 2;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -20px;
                width: 44px;
                height: 20px;
                background-image: url(../images/common/drop-triangle.png);
                margin: 0 auto;
            }
        }
    }

    &__marker {
        width: 28px;
        height: 42px;
        position: absolute;
        top: 35%;
        left: 44%;
        z-index: 2;
        transition: opacity 0.3s ease;

        &.is-open {
            .map-schema__popup {
                opacity: 1;
                pointer-events: auto;
            }
        }

        &-wrap {
            cursor: pointer;
            padding-top: 4px;
            width: 28px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url(../images/map/marker.png);
            font-weight: 600;
            font-size: 12px;
            line-height: 160%;
            color: #fff;
            padding-bottom: 16px;
        }

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
        }
        
        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }

        &--1 {
            top: 43%;
            left: 35%;
        }

        &--2 {
            top: 46.6%;
            left: 38.8%;
        }

        &--3 {
            top: 44.6%;
            left: 39.8%;
        }

        &--4 {
            top: 49.6%;
            left: 40.5%;
            z-index: 16;
        }

        &--5 {
            top: 52.6%;
            left: 38%;
            z-index: 16;
        }

        &--6 {
            top: 59%;
            left: 40.4%;
            z-index: 21
        }

        &--7 {
            top: 61.1%;
            left: 39.9%;
            z-index: 26;
        }

        &--8 {
            top: 61.8%;
            left: 42.6%; 
            z-index: 25;
        }
        &--9 {
            top: 55.6%;
            left: 42.9%;
            z-index: 16;
        }
        &--10 {
            top: 48%;
            left: 43.7%;
            z-index: 14;
        }
        &--11 {
            top: 40%;
            left: 41.3%;
        }
        &--12 {
            top: 42%;
            left: 42.1%;
        }

        &--13 {
            top: 41.7%;
            left: 46.1%;
            z-index: 8;
        }
        &--14 {
            top: 41.1%;
            left: 49.7%;
            z-index: 13;
        }
        &--15 {
            top: 38.6%;
            left: 51.4%;
            z-index: 7;
        }
        &--16 {
            top: 37.3%;
            left: 54.4%;
            z-index: 10;
        }
         &--17 {
                top: 37.3%;
            left: 59.1%;
            z-index: 10;
        } 
        &--18 {
            top: 31.9%;
            left: 58.4%;
            z-index: 6;
        }

        &--19 {
            top: 34.7%;
            left: 60.1%;
            z-index: 9;
        }
        &--20 {
            top: 26.2%;
            left: 66.3%;
            z-index: 5;
        }
        &--21 {
            top: 14.9%;
            left: 68%;
        }
        &--22 {
            top: 48.5%;
            left: 61.1%;
            z-index: 15;
        }
        &--23 {
            top: 57.5%;
            left: 36.6%;
            z-index: 19;
        }

        &--24 {
            top: 54.5%;
            left: 33.3%;
            z-index: 19;
        }

        &--25 {
            top: 53.5%;
            left: 25.1%;
        }

    }

    .btn-block {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-bottom: 147px;
    }

    img {
        display: block;

        &:nth-child(2) {
            display: none;
        }
    }

   
}

@media(max-width: 1279px) {
    .map-schema {
        margin-top: 79px;

        &__container {
            width: 1426px;
            flex: none;

            img {
                max-width: 100%;
            }
        }

        &__marker {
            &--20 {
                top: 25.2%;
            }

            &--21 {
                top: 14.3%;
            }

            &--17 {
                top: 36.7%;
            }

            &--16 {
                top: 36.8%;
            }

            &--15 {
                top: 38.0%;
            }

            &--8 {
                top: 61.0%;
            }

            &--7 {
                top: 60.3%;
            }

            &--14 {
                top: 40.5%;
            }
        }
    }
}


@media(max-width: 960px) {
    .map-schema {
        margin-top: 60px;

        &__container {
            width: 973px;
        }

        .btn-block {
            padding-bottom: 120px;
        }

        &__popup {
            width: 354px;
            left: -169px;
            padding-bottom: 19px;

            &-info {
                margin-bottom: 10px;

                div {
                    margin-bottom: 0;
                }
            }

            &-btn {
                .btn {
                    font-size: 12px;
                    line-height: 14px;
                    padding: 9px 17px;
                }
            }

            &-body {
                &::before {
                    bottom: -12px;
                    width: 26px;
                    height: 12px;
                    background-size: cover;
                }
            }
        }

        &__marker {
            &-wrap {
                width: 17px;
                height: 25px;
                background-position: 50%;
                background-size: cover;
                background-image: url(../images/map/marker-mobile.png);
                padding-bottom: 9px;
                padding-bottom: 12px;
            }
        }
    }
}


@media(max-width: 640px) {
    .map-schema {
        overflow: hidden;

        .btn-block {
            z-index: 2;
        }

        .btn-block {
            padding-bottom: 80px;
        }

        &__marker {
            display: none;
        }

        &__container {
            width: 796px;

            img {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    display: block;
                    margin-bottom: -341px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .map-schema {
        margin-top: -44px;
        pointer-events: none;
    }
}

@media(max-width: 480px) {
    .map-schema {
        margin-top: -23px;

        &__container {
            width: 589px;

            img {
                &:nth-child(2) {
                    margin-bottom: -204px;
                }
            }
        }
    }
}

@media(max-width: 360px) {
    .map-schema {

        &__container {
            width: 511px;

            img {
                &:nth-child(2) {
                    margin-bottom: -161px;
                }
            }
        }
    }
}