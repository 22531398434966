.contact {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 47px;
    margin-bottom: 81px;

    &__item {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #000000;

        a {
            color: #000000;
            text-decoration: none;
        }
    }

    &__name {
        font-size: 17px;
        line-height: 160%;
        letter-spacing: -0.015em;
        color: #8A8A8A;
    }
}

.map {
    height: 530px;

    #map {
        height: 100%;
        width: 100%;
    }
}

@media(max-width: 1279px) {
    .contact {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 14px;
        margin-bottom: 80px;

        &__name {
            font-size: 14px;
            line-height: 160%;
        }

        &__item {
            font-size: 16px;
            line-height: 150%;
            width: auto;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .map {
        height: 400px;
    }
}

@media(max-width: 960px) {
    .contact {
        flex-wrap: wrap;
        margin-top: 3px;
        margin-bottom: 77px;

        &__name {
            font-size: 14px;
            line-height: 160%;
            font-weight: normal;
        }

        &__item {
            font-size: 14px;
            line-height: 160%;
            margin-bottom: 23px;
        }
    }

    .map {
        height: 300px;
    }
}   

@media(max-width: 640px) {
    .contact {
        margin-top: 13px;

        &__item {
            padding-right: 15px;
        }
    }
}

@media(max-width: 480px) {
    .contact {
        margin-top: 6px;

        &__item {
            width: 100%;
            padding-right: 0;
            margin-bottom: 16px;
        }
    }

    .map {
        height: 240px;
    }
}

@media(max-width: 360px) {
    .map {
        height: 200px;
    }
}