.schema-mobile {
    display: none;

}


@media(max-width: 640px) {
    .schema-mobile { 
        display: block;

        &__schema {
            &-wrap {
                position: relative;
                margin-top: -41px;
                z-index: 2;

                &--2 {
                    .schema-mobile__popup {
                        &--management {
                            bottom: 543px;
                            left: 86px;
                        }

                        &--mobile {
                            bottom: 369px;
                            left: 12px;
                        }

                        &--antenna {
                            bottom: 387px;
                            left: 201px;
                        }

                        &--main {
                            bottom: 167px;
                            left: 184px;
                        }
                    }
                }
            }
        }

        &__popup {
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            line-height: 132%;
            color: #fff;
            padding: 6px 12px 8px;
            background: @green;
            box-shadow: 0 7px 12px rgba(0,167,89,.6);
            border-radius: 9px;
            position: absolute;
            text-align: center;

            &--sprayer {
                bottom: 770px;
                left: 132px;
            }

            &--applicator {
                bottom: 770px;
                left: 132px;
            }

            &--seeders {
                bottom: 770px;
                left: 132px;
            }

            &--management {
                bottom: 543px;
                left: 84px;
            }

            &--antenna {
                bottom: 393px;
                left: 202px;
            }

            &--main {
                bottom: 172px;
                left: 190px;
            }

            &--steering {
                bottom: 18px;
                left: 20px;
            }
            

            &--mobile {
                bottom: 373px;
                left: 12px;
            }
        }

        &__title {
            margin-bottom: 12px;
            font-size: 12px;
            line-height: 160%;
            color: #fff;
            text-align: center;
        }

        &__slider {
            width: 320px;
            margin: 0 auto;

            .slick-arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: 36px;
                height: 36px;
                border: 0;
                background-color: #FFFFFF;
                border-radius: 20px;
                display: flex !important;
                padding: 0;
                top: 56px;
                z-index: 5;
                cursor: pointer;


                &.slick-prev {
                    left: calc(~"(100% - 330px)/2*(-1)");
                    padding-right: 2px;

                    .svg-icon {
                        transform: rotate(180deg);
                    }
                }

                &.slick-next {
                    right: calc(~"(100% - 330px)/2*(-1)");
                    padding-left: 2px;
                }

                .svg-icon {
                    use {
                        stroke: #0F1932;
                        fill: transparent;
                    }
                }
            }

            &-container {
              
                position: relative;
                z-index: 5;
                //height: 144px;
            }

            &-back {
                z-index: 1;
                position: absolute;
                width: 274px;
                height: 144px;
                border: 2px solid #1b243c;
                box-sizing: border-box;
                border-radius: 26px;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            &-wrap {
                position: relative;

                &::before {
                    content: " ";
                    width: 226px;
                    height: 144px;
                    position: absolute;
                    border: 2px solid #4e5770;
                    border-radius: 26px;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 3;
                    box-sizing: border-box;
                }

                &::after {
                    content: " ";
                    z-index: 2;
                    width: 253px;
                    height: 144px;
                    position: absolute;
                    border: 2px solid #313C57;
                    border-radius: 26px;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    box-sizing: border-box;
                }
            }
        }

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 194px;
            height: 144px;
            margin: 0 auto;
            border: 2px solid #fff;
            box-shadow: 0 4px 27px hsla(0,0%,100%,.47);
            border-radius: 26px;
            background-color: #0F1932;
            z-index: 5;
            position: relative;

            &-img {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 56px;
                margin-bottom: 7px;

                img {
                    display: block;
                    max-width: 100%;
                }
            }

            &-name {
                font-weight: 600;
                font-size: 14px;
                line-height: 132%;
                color: #fff;
                text-align: center;
                letter-spacing: 0.01em;
            }

            &-wrap {
 
                height: 144px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
}


@media(max-width: 480px) {
    .schema-mobile { 
        &__slider {
            width: 320px;
            margin: 0 auto;

            .slick-arrow {
                &.slick-prev {
                    left: calc(~'(100% - 350px)/2*-1');
                }

                &.slick-next {
                    right: calc(~'(100% - 350px)/2*-1');
                }
            }
        }
    }
}