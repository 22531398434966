.slider-nav {
    width: 73px;

    .slick-current {
        .slider-nav {
            &__item {
                border: 2px solid @green;
            }
        }
    }

    &__item {
        border-radius: 6px;
        margin-bottom: 12px;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, border-width 0.3s ease;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

@media(max-width: 960px) {
    .slider-nav {
        display: none;
    }
}