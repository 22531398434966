.btn-block {
    margin-top: 80px;

    &--center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width: 640px) {
    .btn-block {
        margin-top: 60px;
    }
}

.order-btn {
    .btn {
        width: 100%;
    }
}

.btn {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    text-decoration: none;
    border: 0;
    padding: 8px 17px;
    border-radius: 15px;
    transition: background-color 0.3s, color 0.3s,  box-shadow 0.3s ease;

    &:disabled {
        color: fade(#fff, 50p);
        pointer-events: none;
    }

    &--bold {
        font-weight: 700;
    }

    &--big {
        font-size: 16px;
        line-height: 132%; 
        letter-spacing: 0.03em;
        padding: 16px 39px;
        border-radius: 50px;
    }

    &--middle {
        border-radius: 30px;
        padding: 12px 24px;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
    }

    &--yellow {
        background-color: #F59F00;
        box-shadow: 0px 10px 20px rgba(249, 161, 0, 0.4);
        color: #FFFFFF;
    }

    &--grey {
        background-color: #BDBDBD;
        color: #fff;
    }

   

    &--left-icon {
        .svg-icon {
            margin-right: 6px;
        }

    }

    &--right-icon {
        padding: 8px 13px;

        .svg-icon {
            margin-left: 6px;
            margin-top: -1px;
        }
    }

    &--share {
        .svg-icon {
            use {
                fill: @grey-2;
                stroke: transparent;
            }
        }
    }

    &--out-green {
        color: @green;
        border: 1px solid @green;

        .svg-icon {
            use {
                fill: transparent;
                stroke: @green;
            }
        }
    }

    &--white {
        background-color: #fff;
        color: #1A451A;

        &:hover {
            box-shadow: 0px 11px 25px rgba(0, 167, 89, 0.6);
        }

    }

    &--green {
        color: #FFFFFF;
        background: @green;
        box-shadow: 0px 7px 15px rgba(0, 167, 89, 0.25);

        .svg-icon {
            use {
                fill: transparent;
                stroke: #FFFFFF;
            }
        }
    }

    &--out-white {
        color: #FFFFFF;
        border: 1px solid #fff;
    }

    &--out-grey {
        color: @grey-2;
        border: 1px solid #BDBDBD;

        .svg-icon {
            use {
                fill: transparent;
                stroke: #BDBDBD;
            }
        }
    }

    &--not-shadow {
        box-shadow: none;
    }

}

@media(min-width: 960px) {
    .btn {
        &--yellow {
            &:hover {
                box-shadow: 0px 11px 25px rgba(249, 161, 0, 0.6);
            }

            &:active {
                box-shadow: none;
                background-color: #E69500;
            }
        }

        &--out-white {
            &:hover {
                color: #0F1932;
                background-color: #fff;
            }

            &:active {
                color: #0F1932;
                background-color: #fff;
            }
        }

        &--out-green {
            &:hover {
                background-color: #00a759;
                color: #fff;

                .svg-icon {
                    use {
                        stroke: #fff;
                    }
                }
            }

            &:active {
                background-color: #009952;
            }
        }

        &--green {
            &:hover {
                box-shadow: 0px 11px 25px rgba(0, 167, 89, 0.6);
            }

            &:active {
                box-shadow: none;
                background-color: #009952;
            }
        }
    }
}

@media(max-width: 1279px) {
    .btn {
        &--big {
            font-size: 13px;
            line-height: 132%;
            padding: 16px 28px 15px;
        }

        &--middle {
            font-size: 12px;
            line-height: 132%;
            padding: 11px 24px;
        }
    }
}