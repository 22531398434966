.top-page {
    margin-bottom: 36px;
    position: relative;
    
    h1 {
        margin: 0;
        text-transform: uppercase;
        letter-spacing: initial;
    }

    &__big {
        position: absolute;
        right: 0;
    }

    &__star {
        position: absolute;
        right: 0;
        top: 9px;
        left: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    &--white {
        color: #fff;

        .bread {
            ul {
                li {
                    color: #FFFFFF;

                    a {
                        color: #FFFFFF;

                        svg {
                            path {
                                stroke: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }

    &--buy {
        overflow: hidden;
        padding-bottom: 153px;
        background: linear-gradient(180deg, #183170 -11.49%, #0F1932 24.51%);

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 306px;
            background: linear-gradient(360deg, #0F1932 62.39%, rgba(15, 25, 50, 0) 100%);
        }
    }

    &--card {
        margin-bottom: 0;
        
        h1 {
            width: 50%;
        }
    }

    &__text {
        max-width: 50%;
        margin-top: 23px;
    }

    &--search {
        margin-bottom: 70px;

        h1 {
            font-size: 26px;
            line-height: 132%;
        }
    }

    &--about {
        height: 618px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        text-align: center;
        margin-bottom: 0 !important;
        background: linear-gradient(180deg, @green 0%, #008F4C 100%);

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: url(../images/common/about-back.svg);
            width: 703px;
            height: 618px;
            margin: 0 auto;
        }
        
        h1 {
            font-size: 14px;
            line-height: 150%;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .top-page {
            &__subtitle {
                font-weight: bold;
                font-size: 33px;
                line-height: 132%;
                text-transform: uppercase;
                margin-bottom: 26px;
            }

            &__text {
                margin: 0 auto;
                max-width: 990px;
            }
        }

        .bread {
            z-index: 2;
            padding-bottom: 0;
            position: absolute;
            top: 10px;
            padding-top: 0;

            ul {
                li {
                    a {
                        color: #fff;

                        svg {
                            path {
                                stroke: #fff;
                            }
                        }
                        
                    }

                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
   
}

@media(max-width: 1440px) {
    .top-page {
        &--buy {
            .top-page {
                &__text {
                    font-size: 17px;
                    line-height: 160%;
                    letter-spacing: -0.015em;
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .top-page {
        h1 {
            font-size: 25px;
            line-height: 132%;
        }

        &--product {
            .col-lg-10 {
                width: 100%;
                margin: 0;
            }
        }

        &--contact {
            h1 {
                text-align: center;
            }
        }

        &--card {
            margin-bottom: 0;
        }

        &--buy {
            padding-bottom: 130px;

            h1 {
                font-size: 31px;
                line-height: 132%;
            }

            .bread {
                padding-bottom: 87px;
            }

            .top-page {
                &__text {
                    font-size: 14px;
                    line-height: 160%;
                    max-width: 579px;
                }
            }
        }

        &--about {
            height: 522px;

            &::before {
                width: 655px;
                height: 576px;
                background-size: cover;
            }

            h1 {
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 26px;            
            }

            .top-page {
                &__subtitle {
                    font-size: 25px;
                    line-height: 132%;
                }

                &__text {
                    font-size: 16px;
                    line-height: 160%;
                    max-width: 722px;
                }
            }
        }

    }
}

@media(max-width: 960px) {
    .top-page {

        &__big {
            height: 100%;
            left: inherit;
            right: 0;

            img {
                height: 100%;
            }
        }

        &--card {
            display: none;
        }

        &--buy {
            padding-bottom: 147px;

            h1 {
                font-size: 24px;
                line-height: 132%;
            }

            .top-page {
                &__text {
                    margin-top: 14px;
                    max-width: 100%;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .top-page {
        margin-bottom: 28px;

      

        h1 {
            text-align: center;
            font-size: 19px;
            line-height: 132%;
        }

        &--search {
            h1 {
                text-align: left;
            }
        }

        &--buy {
            background: transparent;
            padding-bottom: 0;
            margin-bottom: 20px;

            h1 {
                color: initial;
                text-align: left;
                font-size: 24px;
                line-height: 132%;
            }

            &::before {
                display: none;
            }

            .bread {
                padding-bottom: 50px;

                ul {
                    justify-content: flex-start;
                    
                    li {
                        color: #8a8a8a;

                        a {
                            color: #8a8a8a;

                            svg path {
                                stroke:  #8a8a8a;
                            }
                        }
                    }
                }
            }

            .top-page {
                &__big {
                    display: none;
                }

                &__text {
                    text-align: left;
                    color: initial;
                    display: none;
                }

                &__star {
                    display: none;
                }
            }
        }

        &--about {
            height: 479px;

            &::before {
                width: 546px;
                height: 480px;
            }

            h1 {
                font-size: 11px;
                line-height: 150%;
            }

            .top-page {
                &__subtitle {
                    font-size: 19px;
                    line-height: 132%;
                }

                &__text {
                    font-size: 13px;
                    line-height: 160%;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .top-page {
        &--about {
            height: auto;
            padding-top: 56px;
            padding-bottom: 50px;

            h1 {
                font-size: 10px;
                line-height: 150%;
            }
        }
    }
}