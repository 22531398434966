.control {
    margin-bottom: -60px;
    
    &__img {
        width: 174px;
        height: 174px;
        margin-right: 30px;
        border-radius: 6px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        text-transform: uppercase;
        color: inherit;
        text-decoration: initial;
        display: inline-flex;
        margin-bottom: 10px;
        transition: color 0.3s ease;
    }

    &__btn {
        display: flex;
        align-items: center;
        margin-top: 18px;

        .btn {
            margin-right: 11px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 60px;

        &--dev {
            .control {
                &__img {
                    opacity: 0.5;
                }
            }
           
        }
    }
}

@media(min-width: 960px) {
    .control {
        &__item {
            &:hover {
                .control {
                    &__name {
                        color: @green;
                    }
                }
                
            }
        }
    }
}

@media(max-width: 1279px) {
    .control {

        &__img {
            width: 150px;
            height: 150px;
        }

        &__name {
            font-size: 15px;
            line-height: 132%;
            margin-bottom: 14px;
        }

        &__item {
            width: 92%;
            margin-bottom: 50px;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }
    }
}

@media(max-width: 640px) {
    .control {
        margin-bottom: -42px;

        &__item {
            width: 100%;
            padding: 0 15px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 42px;
        }

        &__btn {
            justify-content: center;
        }

        &__name {
            font-size: 13px;
            line-height: 132%;
            margin-bottom: 12px;
        }

        &__img {
            width: 190px;
            height: 190px;
            margin: 0;
        }
    }
}

@media(max-width: 480px) {
    .control {
        &__item {
            padding-left: 0;
            padding-right: 0;
        }
    }
}