.hamburger {
    display: none;

    &--mobile {
        display: none;
    }
}

@media(max-width: 960px) {
    .hamburger {
        display: block;
        
        svg {
            display: block;
        }

        &--mobile {
            display: none;
        }
    }
}


@media(max-width: 640px) {
    .hamburger {
        display: none;

        &--mobile {
            display: flex;
            position: fixed;
            z-index: 9;
            align-items: center;
            justify-content: center;
            right: 0;
            top: 0;
            height: 54px;
            width: 54px;
        }



        &__icon {
            transition: transform 0.5s, opacity 0.5s linear;
        }

        &__close {
            position: absolute;
            transform: rotate(45deg);
            opacity: 0;
            transition: transform 0.5s, opacity 0.5s linear;

            use {
                fill: #fff;
            }
        }

        &.is-active {
            .hamburger {
                &__close {
                    transform: rotate(0deg);
                    opacity: 1;
                }

                &__icon {
                    transform: rotate(-45deg);
                    opacity: 0;
                }
            }
        }
    }
}