.menu-adaptive {
    display: none;
}

@media(max-width: 960px) {
    .menu-adaptive {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 105;
        transition: opacity 0.3s ease;
        opacity: 0;
        pointer-events: none;

        &__close {
            position: absolute;
            width: 54px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            right: 0;
            
            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }

        &__top {
            position: absolute;
            display: flex;
            align-items: center;
            left: 0;
            right: 0;
            top: 0;
            height: 54px;
            padding-left: 18px;
            padding-right: 60px;

            .search {
                width: 100%;
                display: flex;
                right: 0;
                position: relative;
                top: auto;
                align-items: center;
                margin: 0;

                &__button {
                    margin-right: 15px;
                }

                &__body {
                    width: auto;
                    flex-grow: 1;

                    input[type=text] {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 132%;
                        width: 100%;
                    }
                }
            }
        }

        &::before {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(to right, rgba(15,25,50,0) 0%,rgba(15,25,50,0.6) calc(~"100% - 360px"),rgba(15,25,50,0.6) 100%);
        }

        &.is-open {
            opacity: 1;
            pointer-events: auto;

            .menu-adaptive {
                &__wrap {
                    transform: translateX(0%);
                }
            }
        }

        .phone {
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
                font-weight: 500;
                font-size: 10px;
                line-height: 132%;
                letter-spacing: 0.03em;
                margin-bottom: 4px;
                display: block;
            }

            a {
                font-weight: 500;
                font-size: 21px;
                line-height: 132%;
                color: #fff;
                text-decoration: none;
                letter-spacing: 0.02em;
                text-transform: uppercase;
            }
        }

        &__container {
            width: 360px;
            height: 100%;
        }
    
        &__wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            z-index: 5;
            padding: 126px 15px 50px;
            width: 360px;
            height: 100%;
            overflow-y: auto;
            background-color: @green;
            transform: translateX(100%);
            transition: transform 0.3s ease;
        }

        &__bottom {
            margin-top: 25px;

            .social {
                margin-top: 52px;
                margin-right: 0;
                justify-content: center;
               

                &__item {
                    margin-right: 17px;
                    background-color: transparent;
                }
            }

            .subscription {
                height: 42px;
            }

            .call-back {
                margin-top: 20px;
                display: flex;
                justify-content: center;

                .btn {
                    font-weight: 700;
                    font-size: 13px;
                    line-height: 132%;
                    letter-spacing: .03em;
                    text-transform: uppercase;
                    color: @green;
                    padding: 16px 48px;
                    border-radius: 50px;
                    box-shadow: 0px 10px 20px rgba(0, 167, 89, 0.27);
                }
            }
        }
    
        .menu {
            display: flex;
            height: auto;

            &>ul {
                flex-direction: column;
                margin: 0 auto;

                &>li {
                    height: auto;
                    margin: 0 0 48px;
    
                    &:last-child {
                        margin-bottom: 0;
                    }
    
                    &>a {
                        font-size: 14px;
                        line-height: 132%;
                    }
                }
            }
    
        }
    }
}


@media(max-width: 640px) {
    .hamburger {
        z-index: 5;
    }

    .menu-adaptive {
        opacity: 1;
        pointer-events: none;

        &::before {
            display: none;
        }

        &__wrap {
            width: 100%;
            transform: translateX(0%);
            opacity: 0;
            background-color: transparent;
            transition: opacity 0.6s ease;
        }

        &__top {
            padding: 0;
            overflow: hidden;

            .search {
                padding-left: 18px;
                padding-right: 60px;
                transform: translateX(100%);
                transition: transform 0.5s ease;
            }
        }

        &__close {
            display: none;
        }

        &__container {
            width: 100%;
            overflow: hidden;

            &::before {
                content: " ";
                position: absolute;
                right: 0;
                top: 0;
                background-color: #00a759;
                width: 200vh;
                height: 200vh;
                transform: scale(0);
                transform-origin: top right;
                border-radius: 50% 0 50% 50%;
                transition: transform 0.6s ease;
            }
        }

        &.is-open {
            opacity: 1;
            pointer-events: auto;

            .menu-adaptive {
                &__container {
                    width: 100%;
                    height: 100%;
                    border-radius: 0;

                    &::before {
                        transform: scale(1);
                    }
                }

                &__wrap {
                    opacity: 1;
                }

                &__top {
                    .search {
                        transform: translateX(0);
                    }
                }

                &__wrap {
                    transform: translateX(0%);

                    
                }
            }
        }
    }
}