.menu {
    height: 100%;

    &>ul {
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        &>li {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-right: 36px;

            &:hover {
                .drop {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            &>a {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 13px;
                line-height: 132%;
                color: #fff;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                text-decoration: none;
                transition: color 0.3s ease;

                .svg-icon {
                    margin-left: 6px;

                    use {
                        fill: transparent;
                        stroke: #fff;
                    }
                }
            }
         }
     }
}

@media(min-width: 960px) {
    .menu {
        &>ul {
            &>li {
                &:hover {
                    &>a {    
                        color: fade(#fff, 60);

                        .svg-icon {
                            use {
                                stroke: fade(#fff, 60);
                            }
                        }
                    }
               } 
            }
        }
    }
}

@media(max-width: 1279px) {
    .menu {
        &>ul {
            &>li {
                margin-right: 20px;

                &>a {
                    font-size: 12px;
                    line-height: 132%;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .menu {
        display: none;
    }
}