.news-small {
    display: flex;
    flex-direction: column;
    
    &__item {
        display: inline-flex;
        text-decoration: none;
        align-items: center;
        color: #000000;
        margin-bottom: 24px;
        transition: color 0.3s ease;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__img {
        width: 72px;
        height: 72px;
        margin-right: 24px;
        flex: none;
        opacity: 0.8;
        border-radius: 9px;
        background-position: center;
        background-size: cover;
        filter: grayscale(100%);
        transition: 0.3s;
    }
}

@media(min-width: 960px) {
    .news-small {
        &__item {
            &:hover {
                color: @green;

                .news-small {
                    &__img {
                        filter: grayscale(0%);
                    }
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .news-small {
        margin: 0 -15px -30px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        &__item {
            width: calc(~"33.3% - 30px");
            margin: 0 15px 30px;
            font-size: 13px;
            line-height: 160%;

            &:last-child {
                margin-bottom: 30px;
            }
        }

        &__img {
            width: 60px;
            height: 60px;
            margin-right: 20px;
        }
    }
}

@media(max-width: 960px) {
    .news-small {
        &__item {
            width: calc(~"50% - 30px");
        } 
    }
}

@media(max-width: 640px) {
    .news-small {
        margin-bottom: -20px;

        &__item {
            width: calc(~"100% - 30px");
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 20px;
            }
        } 
    }
}