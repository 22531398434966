.search-list {
    max-width: 66.6%;

    &__item {
        display: flex;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }

        .bread {
            padding: 0;
            margin-bottom: 6px;

            ul {
                li {
                    a {
                        font-size: 13px;
                        line-height: 160%;
                    }

                    span {
                        font-size: 13px;
                        line-height: 160%;
                    }
                }
            }
        }
    }

    &__name {
        display: inline-flex;
        text-transform: uppercase;
        text-decoration: none;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        color: #000;
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 174px;
        height: 174px;
        flex: none;
    }

    &__body {
        padding-left: 30px;
    }
    
    &__text {
        a {
            color: @green;
            text-decoration: none;
        }
    }
}

@media(max-width: 1280px) {
    .search-list {
        max-width: initial;
    }
}

@media(max-width: 640px) {
    .search-list {
        &__img {
            display: none;
        }

        &__item {
            .bread {
                ul {
                    justify-content: flex-start;
                }
            }
        }

        &__text {
            font-size: 13px;
        }

        &__name {
            margin-bottom: 8px;
            font-size: 16px;
        }

        &__body {
            padding-left: 0;
        }
    }
}