.drop {
    position: absolute;
    left: 0;
    top: 100%;
    width: 303px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    ul {
       
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-top: 2px;
        margin: 0;

        li {
            list-style: none;
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: flex;
                align-items: center;
                padding: 21px;
                font-weight: bold;
                font-size: 13px;
                line-height: 132%;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: #FFFFFF;
                text-decoration: none;
                background-color: @green;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #00B25F;
                }
            }
        }
    }
}