.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 50;

    &.is-fixed {
        .logo {
            &__main {
                opacity: 0;
                pointer-events: none;
            }

            &__dop {
                left: 0;
            }
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 78px;
        background-color: @green;
        padding: 0 34px 0 47px;
    }

    &__main {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 48px;
    }

    &__aside {
        display: flex;
        align-items: center;
    }

    .logo {
        display: inline-flex;
        align-items: center;
        position: relative;

        &__main {
            opacity: 1;
            transition: opacity 0.4s ease;
        }

        &__dop {
            position: absolute;
            left: 195px;
            top: 0;
            transition: left 0.4s ease;
        }
    }
}


@media(min-width: 1440px) {
    .header {
        &__wrap {
            transition: height 0.3s ease;
        }

        
    }

    .header.is-fixed {
        .header {
            &__wrap {
                height: 66px;
            }
        }

        
    }
}

@media(max-width: 1440px) {
    .header {
        &__wrap {
            height: 60px;
            padding-left: 25px;
            padding-right: 22px;
        }

        .logo {
            &__dop {
                left: 172px;
            }
        }
    }
}

@media(max-width: 1279px) {
    .header {
        &__wrap {
            height: 54px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .logo {
            width: 193px;

            &__dop {
                left: 166px;
                width: 29px;
            }
        }

        &.is-fixed {

            .logo {
                &__main {
                    opacity: 0;
                    pointer-events: none;
                }
    
                &__dop {
                    left: 0;
                }
            }
        }

       
    }
}

@media(max-width: 960px) {
    .header {
        &__wrap {
            padding-left: 17px;
            padding-right: 17px;
        }

        &__main {
            padding-right: 0;
        }

        .logo {
            width: 175px;

            &__dop {
                left: 151px;
                width: 25px;
            }
        }
    }
}


@media(max-width: 480px) {
    .header {
        .logo {
            width: 155px;

            &__dop {
                left: 134px;
                width: 22px;
            }
        }
    }
}