.anchors {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 38px;

    &__item {
        cursor: pointer;
        font-weight: 500;
        font-size: 17px;
        line-height: 1.4;
        letter-spacing: -.015em;
        margin-bottom: 23px;
        border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
        transition: border-color 0.3s, color 0.3s ease;

        &.is-active {
            color: @green;
            border-color: @green;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(max-width: 1279px) {
    .anchors {
        margin-bottom: 40px;
        
        &__item {
            font-size: 14px;
            line-height: 160%;
            margin-bottom: 18px;
        }
    }
}