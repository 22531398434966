.advantages {
    display: flex;
    flex-wrap: wrap;
    margin: 77px -32px -44px;

    &__item {
        display: flex;
        width: 33.3%;
        padding: 0 32px;
        margin-bottom: 44px;
    }

    &__icon {
        flex: none;
        margin-right: 24px;
        margin-top: -2px;
    }

    &__text {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.015em;
        color: #FFFFFF;
    }
}

@media(max-width: 1279px) { 
    .advantages {
        margin-left: 0;
        margin-right: 0;
        width: 86%;
        margin: 83px auto -44px;

        &__item {
            width: 50%;
            padding: 0 30px;
        }

        &__icon {
            width: 32px;
            height: 32px;
            margin-right: 19px;

            svg {
                width: 100%;
                height: auto;
            }
        }

        &__text {
            font-size: 15px;
            line-height: 150%;
            letter-spacing: -0.015em;
        }
    }
}

@media(max-width: 960px) { 
    .advantages {
        margin-top: 71px;
        width: 100%;

        &__icon {
            width: 29px;
            height: 29px;
        }
    }
}

@media(max-width: 640px) {
    .advantages {
        margin-top: 0;
        margin-bottom: -28px;

        &__item {
            width: 100%;
            margin-bottom: 28px;
        }

        &__text {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
        }

        &__icon {
            width: 28px;
            height: 28px;
            margin-right: 19px;
            margin-top: 0;
        }
    }
}

@media(max-width: 480px) {
    .advantages {
        margin-bottom: -22px;

        &__item {
            padding-left: 3px;
            padding-right: 3px;
            margin-bottom: 22px;
        }

        &__icon {
            margin-top: -2px;
        }
    }
}

@media(max-width: 360px) {
    .advantages {
        &__item {
            padding-left: 0;
            padding-right: 0;
        }
    }   
}