.section {
    padding: 113px 0;

    &--pt0 {
        padding-top: 0 !important;
    }

    &--grey {
        background-color: @grey-1;
    }

    &--mobile {
        display: none;
    }

    &__top {
        text-align: center;
        margin-bottom: 56px;

        h3 {
            margin: 0;
            text-align: center;
            margin-bottom: 20px;
        }

        &--left {
            h3 {
                text-align: left;
            }
        }
    }

    &__text {
        font-weight: bold;
        font-size: 17px;
        line-height: 160%;
        max-width: 785px;
        margin: 0 auto;
    }

    &__btn {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }

    &__link {
        a {
            font-weight: bold;
            font-size: 12px;
            line-height: 160%;
            color: @green;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            text-decoration: none;
            transition: border-color 0.3s ease;
            border-bottom: 2px solid transparent;
        }
    }
}

@media(min-width: 960px) {
    .section {
        &__link {
            a {
                &:hover {
                    border-color: @green;
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .section {
        padding: 85px 0;    

        &__text {
            font-size: 14px;
            line-height: 160%;
            max-width: 634px;
        }

        &__top {
            margin-bottom: 50px;
        }

        &--mobile {
            display: block;
        }
    }
}

@media(max-width: 960px) {
    .section {
        padding: 70px 0;

        &__text {
            padding: 0 15px;
        }
    }
}

@media(max-width: 640px) {
    .section {

        &__top {
            margin-bottom: 40px;
        }
    }
}

@media(max-width: 480px) {
    .section {
        padding: 60px 0;

        &__text {
            padding: 0;
        }

        &__top {
            margin-bottom: 41px;

            h3 {
                margin-bottom: 30px;
            }
        }
    }
}