.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: fade(#0F1932 , 20);
    z-index: 50;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &__close {
        cursor: pointer;
        margin-left: 5px;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &__wrap {
        width: 591.11px;
        max-width: 100%;
        border-radius: 12px;
        overflow: hidden;
        transform: translateY(100vh);
        transition: transform 0.5s linear;
    }

    &__body {
        position: relative;
        padding: 60px;
        border-radius: 12px;
        background-color: #fff;
    }

    &__text {
        margin-bottom: 20px;
        
        a {
            color: @green;
            text-decoration: none;
            white-space: nowrap;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 24px 25px;
       /* background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(19px);*/
        margin-bottom: -10px;
        background-color: @green;
    }

    &__main {
        transition: opacity 0.3s ease;
    }

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
    }

    &.is-ok {
        .popup {
            &__main {
                opacity: 0;
                pointer-events: none;
            }
        }

        .form-ok {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &.is-open {
        opacity: 1;
        pointer-events: auto;

        .popup {
            &__wrap {
                transform: translateY(0);
            }
        }
    }
}

@media(max-width: 960px) {
    .popup {
        &__wrap {
            width: 479px;
            
        }

        &__body {
            padding: 36px;
        }
    }
}

@media(max-width: 640px) {
    .popup {
        padding: 25px;

        &__wrap {
            width: 100%;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }

        &__body {
            padding: 24px;
        }
    }
}


@media(max-width: 420px) {
    .popup {
        padding: 16px;

        &__top {
            padding: 15px 13px;
        }
    }
}