.logo-bottom {
    display: inline-flex;
    margin-bottom: 36px;
    width: 269px;
    
    img {
        width: 100%;
        display: block;
    }
}

@media(max-width: 1279px) { 
    .logo-bottom {
        margin-bottom: 30px;
    }
}

@media(max-width: 960px) { 
    .logo-bottom {
        width: 180px;
        margin-bottom: 25px;
    }
}

@media(max-width: 640px) { 
    .logo-bottom {
        width: 174px;
        margin-bottom: 25px;
    }
}

@media(max-width: 480px) { 
    .logo-bottom {
        width: 150px;
        margin-bottom: 0;
    }
}

@media(max-width: 1440px) {
    .logo {
        width: 196px;
        display: flex;
        align-items: center;

        img {
            max-width: 100%;
            display: block;
        }
    }
}


@media(max-width: 1279px) {
    .logo {
        width: 177px;
    }
}

@media(max-width: 640px) {
    .logo {
        width: 156px;
    }
}
