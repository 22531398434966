.gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -30px;

    &--mobile {
        display: none;
    }

    &__bottom {
        display: flex;
        align-items: center;
        z-index: 1;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        width: 54px;
        height: 54px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        margin-right: 14px;
        transition: background-color 0.3s ease;
    }

    &__name {
        font-weight: 500;
        font-size: 15px;
        line-height: 132%;
        letter-spacing: -0.015em;
    }


    &__item {
        display: flex;
        align-items: flex-end;
        position: relative;
        border-radius: 12px;
        color: #FFFFFF;
        text-decoration: none;
        overflow: hidden;
      
        height: 280px;
        padding: 30px;
        position: relative;
        background-position: center;
        background-size: cover;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 37.86%, rgba(0, 0, 0, 0.6) 100%);
        }
    }
}

@media(max-width: 1279px) {
    .gallery {
        &__item {
            height: 205px;
            padding: 20px;
        }

        &__icon {
            width: 42px;
            height: 42px;
            margin-right: 14px;

            svg {
                max-width: 19px;
                height: auto;
            }
        }

        &__name {
            font-size: 14px;
            line-height: 132%;
        }
    }
}

@media(min-width: 960px) {
    .gallery {
        margin: 0 -15px;

        &__container {
            margin: 0 15px 30px;
            width: calc(~"50% - 30px");
        }

        &__item {
            &:hover {
                .gallery {
                    &__icon {
                        background-color: @green;
                    }
                }
            }
        }
    }
}


@media(max-width: 960px) {
    .gallery {
        margin: 0 -9px;

        &__item {
            padding: 20px 23px;
        }
    }
}

@media(min-width: 640px) {
    .gallery {
        margin: 0 -9px;

        &__container {
            width: calc(~"50% - 18px");
            margin: 0 9px 22px;
        }
    }
}

@media(max-width: 640px) {
    .gallery {

        &--mobile {
            display: block;
        }

        &__name {
            display: none;

            &--mobile {
                display: block;
                margin-top: 16px;
                text-decoration: none;
                color: inherit
            }
        }

        &__icon {
            margin-right: 0;
        }

        .slick-dots {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            li {
                list-style: none;
                margin: 0 2px;


                button {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    padding: 0;
                    border: 0;
                    font-size: 0;
                    background-color: @grey-1;
                }

                &.slick-active {
                    button {
                        background-color: @green;
                    }
                }

            }
        }

        &--desc {
            display: none;
        }

        &__mobile {
            display: block;
        }

        &__item {
            width: 100%;
            height: 64vw;
            align-items: center;
            justify-content: center;
        }

        &__container {
            width: auto;
            margin: 0;
        }
    }
}