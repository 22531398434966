.footer {
    background-color: #000;
    z-index: 5;
    position: relative;
    flex: none;

    &__mobile {
        display: none;
    }

    &__wrap {
        display: flex;
        padding: 50px 0 50px;
        justify-content: space-between;
    }


    &__body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-grow: 1;
        padding-left: 97px;
    }

    &__top {
        display: flex;
        align-items: center;
    }

    &__info {
        width: 312px;
        flex: none;
    }

    &__link {
        color: @grey-2;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;

        &--mobile {
            display: none;
        }

        div {
            display: inline-flex;
        }

        a {
            display: inline-flex;
            color: @grey-2;
            text-decoration: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__main-info {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 47px;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
    }
}

@media(max-width: 1279px) {
    .footer {
        .subscription {
            display: none;
        }

        &__body {
            align-items: center;
            padding-left: 65px;
        }

        &__wrap {
            padding: 44px 0;
        }

        &__link {
            font-size: 12px;
            line-height: 160%;

            a {
                margin-bottom: 5px;
            }

            div {
                margin-bottom: 5px;
            }
        }

        &__bottom {
            height: 100%;
        }

        &__mobile {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 100%;
            justify-content: space-between;

            &-wrap {
                display: flex;
                align-items: center;
            }

            .subscription {
                display: block;
            }
        }

        

        &__main {
            &-info {
                display: none;
            }
        }
    }
}

@media(max-width: 960px) {
    .footer {
        &__link {
            font-size: 10px;
            line-height: 160%;
        }

        &__info {
            width: 210px;
        }

        &__wrap {
            padding: 42px 0;
        }
    }
}


@media(max-width: 480px) {
    .footer {
        &__link {
            display: none;

            &--mobile {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
            }
        }

        &__body {
            padding-left: 0;
            align-items: center;
            justify-content: center;
        }

        &__info {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
        }

        &__wrap {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}
