.card {
    overflow: hidden;
    margin: 0 -35px;
    padding: 0 35px;

    &__slider {
        margin-bottom: 120px;

        h1 {
            margin-top: 0;
            font-size: 33px;
            line-height: 132%;
            max-width: 685px;
            text-transform: uppercase;
        }

        &-wrap {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-right: 74px;
        }
       
    }

    &__fixed {
        display: none;
    }

    &__mobile {
        display: none;
    }

    &__container {
        padding-right: 56px;
        float: left;
        width: calc(~"100% - 278px");
    }

    &__aside {
        float: left;
        width: 278px;
        padding-right: 72px;
        box-sizing: border-box;
    }

    &__btn {
        margin-top: 46px;

        .btn {
            width: 100%;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }


    &__item {
        margin-bottom: 120px;
        padding-right: 75px;

        &-control {
            display: none;
        }

        .btn-block {
            margin-top: 42px;
            padding-bottom: 35px;
        }

        &:last-child {
            margin-bottom: 0;
        }

       h3 {
            margin: 0;
            margin-bottom: 36px;
            text-align: left;
       }
    }

    &__about {
        p {
            font-size: 17px;
            line-height: 160%;
            margin: 0;
            margin-bottom: 36px;

            &:last-child {
                margin-bottom: 0;
            }
        }



        h5 {
            margin: 0 0 12px;
        }
    }
}

.card-top {
    display: none;
}

@media(max-width: 1279px) {
    .card {
        &__aside {
            width: 196px;
            padding-right: 0;
        }

        &__container {
            padding-right: 34px;
            width: calc(~'100% - 196px');
        }

        &__about {
            h5 {
                font-size: 15px;
                line-height: 132%;
            }

            p {
                font-size: 14px;
                line-height: 160%;
                margin-bottom: 33px;
            }
        }

        &__slider {
            margin-bottom: 96px;

            h1 {
                font-size: 25px;
                line-height: 132%;
                max-width: 576px;
                margin-bottom: 59px;
            }
        }

        &__btn {
            margin-top: 49px;
        }

        &__item {
            margin-bottom: 100px;
        }
    }
}

@media(max-width: 960px) {
    .card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 54px;
        padding: 0 20px 0 30px;
        margin-bottom: 20px;

        .link {
            &__item {
                margin-bottom: 0;
                

                span {
                    display: none;
                }
            }

            &__icon {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0;

                .svg-icon {
                    width: 21px;
                    height: auto;

                    use {
                        fill: #BDBDBD;
                    }
                }
            }
        }
    }

    
    .card {
        &__aside {
            display: none;
        }

        &__slider {
            margin-bottom: 39px;
            &-wrap {
                padding-right: 0;
                align-items: center;
                justify-content: center;
            }

            h1 {
                display: none;
            }
        }

        &__fixed {
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
            box-shadow: 0 -4px 12px rgba(0,0,0,.08);
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            padding: 19px 30px;
            transform: translateY(104%);
            transition: transform 0.3s ease;

            .btn {
                padding: 11px 20px 11px;
                font-size: 12px;
                line-height: 132%;
                letter-spacing: 0.03em;
                flex: none;
            }

            &.is-active {
                transform: translateY(0);
            }

            .card {
                &__title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 132%;
                    margin-bottom: 0;
                    padding-right: 15px;
                    margin: 0;
                    text-align: left;
                }

                
            }
        }

        &__mobile {
            display: block;
            margin-bottom: 60px;

            &-btn {
                display: flex;
                align-items: center;
                justify-content: center;

                .btn {
                    width: auto;
                }
            }

            .btn-small {
                display: none;

                &__item {
                    &--site {
                        width: 210px;
                    }
                }
            }
        }

        &__title {
            font-weight: bold;
            font-size: 25px;
            line-height: 132%;
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;
            max-width: 53%;
            margin: 0 auto 30px;
        }

        &__slider {
            margin-bottom: 30px;
        }

        &__container {
            float: none;
            width: 100%;
            padding-right: 0;
        }

        &__about {
            font-size: 14px;
            line-height: 160%;

            p {
                margin-bottom: 32px;
            }

            h5 {
                font-size: 15px;
                line-height: 132%;
                letter-spacing: initial;
            }
        }

        &__item {
            margin-bottom: 0;
            margin: 0 -30px;
            padding: 0;

            &:last-child {
                .card__item {
                    &-inner {
                        padding-bottom: 0;
                    }
                }
                
            }

            .btn-block {
                display: flex;
                justify-content: center;
            }

            &-inner {
                padding: 0 30px;
                padding-top: 32px;
                padding-bottom: 48px;
                display: none;
                background: linear-gradient(360deg, #F7F7F7 0%, rgba(247, 247, 247, 0) 47.59%);
            }

            &-top {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 0 30px;
                margin-top: 41px;

                &.is-active {
                    .card {
                        &__item {
                            &-control {
                                svg {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                    }
                }
               
                
                h3 {
                    margin-bottom: 0;
                }
            }

            &-control {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                flex: none;
                background-color: #F3F3F3;
                border-radius: 6px;

                svg {
                    transform: rotate(180deg);
                    transition: transform 0.3s ease;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .card {
        &__slider {
            margin-bottom: 40px;
        }

        &__fixed {
            padding: 15px 30px;

            .card {
                &__title {
                    font-size: 13px;
                    line-height: 132%;
                }
            }
        }

        &__title {
            font-size: 19px;
            line-height: 132%;
        }

        &__mobile {
            margin-bottom: 136px;

            .btn-small {
                &__item {
                    &--site {
                        width: auto;
                    }
                }
            }

            &-btn {
                .btn {
                    font-size: 12px;
                    line-height: 132%;
                    padding: 11px 21px 11px;
                }
            }
        }

        &__about {
            h5 {
                font-size: 13px;
                line-height: 132%;
            }

            p {
                margin-bottom: 41px;
            }
        }

        &__item {
            &-top {
                height: 36px;
                margin-top: 36px;
            }

            &-inner {
                padding-top: 36px;
            }

            &-control {
                width: 30px;
                height: 30px;
            }
        }
    }

    .card-top {
        padding-right: 10px;
    }
}

@media(max-width: 480px) {
    .card {

        &__mobile {
            margin-bottom: 90px;
        }

        &__about {
            p {
                margin-bottom: 36px;
            }
        }

        &__title {
            max-width: inherit;
            margin-bottom: 23px;
        }

        &__fixed {
            padding-left: 24px;
            padding-right: 24px;
        }

    }

    .card-top {
        padding-left: 24px;
    }
}