.pic {
    margin-bottom: 48px;
    margin-top: 45px;

    img {
        display: block;
        margin-bottom: 0;
        border-radius: 10px;
    }

    &__bottom {
        margin-top: 10px;
    }

    &__date {
        color: #BDBDBD;
    }

}