.about-list {
    display: flex;
    flex-wrap: wrap;
    margin: 100px -16px 0;

    &__item {
        margin: 0 16px;
        width: calc(~"25% - 32px");
    }

    &__icon {
        display: flex;
        align-items: center;
        width: 92px;
        height: 92px;
        margin-bottom: 16px;
    }

    &__name {
        font-weight: bold;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        margin-bottom: 16px;
        max-width: 90%;
    }
}


@media(max-width: 1279px) {
    .about-list {
        margin-top: 64px;
        margin-left: 0;
        margin-right: 0;

        &__item {
            width: 70%;
            margin: 0 auto 48px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__icon {
            width: 60px;
            height: 60px;
            margin: 0 auto 18px;

            img {
                max-width: 100%;
            }
        }

        &__name {
            font-size: 14px;
            line-height: 160%;
            margin: 0 0 20px 0;
            text-align: center;
            letter-spacing: -0.015em;
            max-width: initial;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
            letter-spacing: -0.015em;
            text-align: center;
        }
    }
}


@media(max-width: 640px) {
    .about-list {
        &__name {
            margin-bottom: 18px;
        }
    }
}

@media(max-width: 480px) {
    .about-list {
        &__item {
            width: 100%;
        }
    }
}