.buy {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -73px;
    margin-top: 114px;

    &__map {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 7px 12px;
        background: #F3F3F3;
        border-radius: 15px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #00A759;
        margin-left: 10px;
        text-decoration: none;
        margin-bottom: -2px;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }

    &__item {
        width: calc(~"33.3% - 30px");
        margin: 0 15px 73px;
    }

    &__top {
        display: flex;
        align-items: center; 
        margin-bottom: 7px;

        .svg-icon {
            margin-right: 10px;
            margin-top: 4px;

            use {
                fill: @green;
                stroke: @green;
            }
        }
    }

    &__city {
        color: @green;
       
    }

    &__name {
        font-weight: bold;
        font-size: 17px;
        line-height: 160%;
        letter-spacing: -0.015em;
        text-transform: uppercase;
        margin-bottom: 18px;
    }

    &__body {
        div {
            margin-bottom: 9px;
        }
    }

    &__mail {
        a {
            color: @green;
            text-decoration: none;
        }
    }
}

@media(max-width: 1279px) {
    .buy {
        margin-top: 66px;

        &__name {
            font-size: 14px;
            line-height: 160%;
        }

        &__body {
            font-size: 14px;
            line-height: 160%;

            div {
                margin-bottom: 8px;
            }
        }
    }
}

@media(max-width: 960px) {
    .buy {
        &__item {
            width: calc(~"50% - 30px");
        }
    }
}


@media(max-width: 640px) {
    .buy {
        margin-top: 42px;
        margin-bottom: -30px;

        &__item {
            width: calc(~'100% - 30px');
            margin-bottom: 30px;
        }
    }
}