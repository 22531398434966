.back {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 160%;
    color: #8A8A8A;
    text-decoration: none;

    .svg-icon {
        margin-right: 8px;

        use {
            stroke:  #8A8A8A;
        }
    }
}