.doc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    &__item {
        display: inline-flex;
        align-items: center;
        color: @green;
        text-decoration: none;
    }

    &__icon {
        flex: none;
        margin-right: 15px;
        
        img {
            display: block;
        }
    }

    &__name {
        font-size: 17px;
        line-height: 160%;
        color: @green;
        letter-spacing: -0.015em;
    }

    &__size {
        color: @grey-2;
        text-transform: uppercase;
        margin-left: 3px;
    }
}