.tab {

    &__top {
        display: flex;
        justify-content: space-between;

        &-body {
            width: calc(~"100% - 378px");
            padding-right: 25px;
        }
    }

    &__aside {
        width: 378px;
        flex: none;

        .select {
            margin-top: -37px;
        }
    }

    &__control {
        display: flex;
        margin-top: -83px;

        &-item {
            cursor: pointer;
            color: #fff;
            font-weight: 600;
            font-size: 17px;
            line-height: 160%;
            padding: 10px 24px;
            border-radius: 6px 6px 0px 0px;
            transition: background-color 0.3s , color 0.3s ease;

            &.is-active {
                background-color: #FFFFFF;
                color: #0F1932;
            }
        }
    }

    &__block {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__wrap {
        padding-top: 90px;

        h2 {
            margin: 0 0 0;
            text-transform: uppercase;
        }
    }
}

@media(min-width: 960px) {
    .tab {
        &__control {
            &-item {
                &:hover {
                    background-color: #FFFFFF;
                    color: #0F1932;
                }
            }
        }
    }
}

@media(max-width: 1440px) {
    .tab {
        &__wrap {
            padding-top: 126px;
        }
    }
}

@media(max-width: 1279px) {
    .tab {
        &__control {
            margin-top: -81px;

            &-item {
                font-size: 16px;
                line-height: 160%;
                padding: 10px 24px;
            }
        }

        &__aside {
            width: 272px;
        }

        &__top {
            &-body {
                width: calc(~'100% - 272px');
            }
        }

        &__wrap {
            padding-top: 107px;

            h2 {
                font-size: 25px;
                line-height: 132%;
            }
        }
    }
}

@media(max-width: 960px) {
    .tab {
        &__control {
            margin-top: -74px;

            &-item {
                font-size: 14px;
                line-height: 160%;
                padding: 8px 20px;
            }
        }

        &__wrap {
            padding-top: 77px;
        }

        &__top {
            &-body {
                width: calc(~'100% - 281px');
            }
        }

        &__aside {
            width: 281px;

            .select {
                margin-top: -7px;
            }
        }
    }
}

@media(max-width: 640px) {
    .tab {

        &__wrap {
            padding-top: 15px;
        }

        &__aside {
            width: 100%;

            .select {
                margin-top: 0;
            }
        }

        &__control {
            flex-wrap: nowrap;
            margin-top: 0;
            margin-right: -18px;
            margin-bottom: -10px;
            display: block;

            &-item {
                display: inline-flex;
                font-size: 14px;
                line-height: 160%;
                font-weight: normal;
                color: #000000;
                background-color: @grey-1;
                border-radius: 5px;
                margin-right: 4px;
                margin-bottom: 8px;
                padding: 5px 14px 6px;

                &.is-active {
                    background-color: @green;
                    color: #fff;
                }
            }
        }

        &__top {
            display: block;

            &-body {
                width: 100%;
                padding-right: 0;
                margin-bottom: 20px;

                h2 {
                    display: none;
                }
            }
        }
    }
}