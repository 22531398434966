.bread {
    padding: 14px 47px 95px;

    ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            font-size: 14px;
            line-height: 160%;
            color: @grey-2;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            a {
                color: @grey-2;
                text-decoration: none;

                svg {
                    margin-left: 8px;
                    transform: translateY(3px);

                    path {
                        stroke: @grey-2;
                    }
                }
            }
        }
    }
}

@media(max-width: 1280px) {
    .bread {
        padding-left: 42px;
        padding-right: 42px;

        ul {
            li {
                font-size: 12px;
                line-height: 160%;

                a {
                    svg {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .bread {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media(max-width: 640px) {
    .bread {
        padding-bottom: 58px;
        padding-left: 24px;
        padding-right: 24px;

        ul {
            justify-content: center;
            align-items: center;

            li {
                font-size: 10px;
                line-height: 160%;
                margin-right: 6px;

                a {
                    svg {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}