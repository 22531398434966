.history {
    display: flex;
    justify-content: center;
    width: 990px;
    margin: 0 auto;

    &__main {
        width: 378px;
        margin-right: 32px;
        padding-left: 25px;
        flex: none;
        text-align: right;

        h2 {
            margin: 0 0 24px;
            font-weight: bold;
            font-size: 33px;
            line-height: 132%;
            text-transform: uppercase;
        }
    }
    
    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 43px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__body {
        width: 580px;
    }

    &__date {
        width: 172px;
        flex: none;
        margin-right: 30px;
        font-size: 21px;
        line-height: 132%;
        font-weight: bold;
        color: @green;
        text-transform: uppercase;
        text-align: center;
    }

    &__text {
        font-size: 17px;
        line-height: 160%;
    }
}

@media(min-width: 960px) {
    .history {
        display: block;
        overflow: hidden;

        &__main {
            float: left;
        }

        &__body {
            float: right;
        }
    }
}

@media(max-width: 1279px) {
    .history {
        width: auto;

        &__main {
            width: 347px;
            margin-right: 30px;
            padding-left: 0;

            &-text {
                font-size: 14px;
                line-height: 160%;
                text-align: left;
            }

            h2 {
                font-size: 25px;
                line-height: 132%;
                margin-bottom: 18px;
                text-align: left;

                br {
                    display: none;
                }
            }
        }

        &__text {
            font-size: 14px;
            line-height: 160%;
        }

        &__date {
            width: 120px;
            font-size: 17px;
            line-height: 132%;
        }

        &__body {
            width: 498px;
        }
    }
}

@media(max-width: 960px) {
    .history {
        display: block;

        &__main {
            width: 100%;
            margin-right: 0;
            margin-bottom: 39px;
        }

        &__item {
            margin-bottom: 30px;
        }

        &__body {
            width: 100%;
        }

        &__date {
            width: 82px;
            margin-right: 18px;
            text-align: left;
        }
    }
}

@media(max-width: 640px) {
    .history {
        &__main {
            margin-bottom: 33px;

            h2 {
                font-size: 19px;
                line-height: 132%;
            }
        }
    }
}

@media(max-width: 480px) {
    .history {
        &__item {
            display: block;
        }

        &__date {
            font-size: 17px;
            line-height: 132%;
            width: auto;
            margin: 0 0 6px;
        }
    }
}