.about {
    &__item {
        height: 640px;
        display: flex;
        align-items: center;

        &:nth-child(even) {
            justify-content: flex-end;
        }
    }

    &__img {
        flex: none;
        background-position: center;
        background-size: cover;
    }

    &__text {
        font-size: 17px;
        line-height: 160%;
    }

    &__title {
        font-weight: bold;
        font-size: 26px;
        line-height: 132%;
        text-transform: uppercase;
        margin-bottom: 30px;

        span {
            display: block;
            color: @green;
        }
    }

    &__body {
        width: 50%;
    }
}



@media(min-width: 960px) {
    .about {
        margin-bottom: 108px;

        &__img {
            height: 400px;
            width: calc(~"100vw - 498px - 70px");
            transform: translateX(100%);
            transition: transform 0.8s ease;
        }

        &__body {
            width: 498px;
            flex: none;
            margin-right: 70px;
            opacity: 0;
            transition: opacity 0.5s ease;
            animation-delay: 0.3s;
        }

        &__item {
            &:nth-child(even) {
                .about {
                    &__body {
                        margin-right: 0;
                        margin-left: 70px;
                    }

                    &__img {
                        transform: translateX(-100%);
                    }
                }
            }

            &.is-active {
                .about {
                    &__body {
                        opacity: 1;
                    }

                    &__img {
                        transform: translateX(0%);
                    }
                }
            }
        }
    }
}

@media(min-width: 1279px) {
    .about {
        &__img {
            height: 100%;
            width: calc(~"100vw - 582px - 132px");
        }

        &__body {
            width: 582px;
            flex: none;
            margin-right: 132px;
        }

        &__item {
            &:nth-child(even) {
                .about {
                    &__body {
                        margin-right: 0;
                        margin-left: 132px;
                    }
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .about {
        &__item {
            height: 400px;
        }

       
        &__text {
            font-size: 14px;
            line-height: 160%;
        }

        &__title {
            font-size: 20px;
            line-height: 132%;
            margin-bottom: 18px;
        }
    }
}

@media(max-width: 960px) {
    .about {
        margin-top: 84px;
        margin-bottom: 96px;

        &__item {
            height: auto;
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__body {
            width: 100%;
        }

        &__title {
            font-size: 20px;
            line-height: 132%;
            margin-bottom: 18px;
        }
    }
}

@media(max-width: 640px) {
    .about {
        margin-top: 55px;
        margin-bottom: 62px;

        &__item {
            height: auto;
            margin-bottom: 36px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__title {
            font-size: 16px;
            line-height: 132%;
            margin-bottom: 18px;
        }
    }
}