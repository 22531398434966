.news {
    display: flex;
    margin: 0 -24px;

    &__item {
        width: calc(~"33.3% - 48px");
        margin: 0 24px;
        position: relative;
    }

    &__name {
        font-weight: 600;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: #000;
        text-decoration: none;
        margin-bottom: 12px;
        display: inline-flex;
        transition: color 0.3s ease;
    }

    &__time {
        position: absolute;
        top: 249px;
        left: 0;
        border-radius: 6px;
        display: inline-flex;
        padding: 6px 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 160%;
        color: #FFFFFF;
        background-color: @green;
        text-transform: uppercase;
    }

    &__img {
        display: flex;
        width: 100%;
        height: 265px;
        background-position: center;
        background-size: cover;
        margin-bottom: 27px;
        position: relative;
        overflow: hidden;
        background-color: #000;
        filter: grayscale(100%);
        transition: 0.3s;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: #FFFFFF;
            opacity: 0.2;
        }
    }

    &__slider {
        margin: 0 -25px;

        .news {
            &__item {
                width: calc(~"100% - 50px");
                margin: 0 25px;
            }
        }
    }
}

@media(min-width: 960px) {
    .news {
        &__item {
            &:hover {
                .news {
                    &__img {
                        filter: grayscale(0%);
                    }

                    &__name {
                        color: @green;
                    }
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .news {
        &__slider {
            margin: 0 -15px;

            .news {
                &__item {
                    width: calc(~"100% - 30px");
                    margin: 0 15px;
                }
            }
        }

        &__img {
            height: 196px;
            margin-bottom: 25px;
        }

        &__time {
            top: 180px;
        }

        &__name {
            font-size: 14px;
            line-height: 160%;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
            letter-spacing: -0.015em;
        }
    }
}

@media(max-width: 960px) {
    .news {
        &__slider {
            margin: 0 -30px;

            .news {
                &__item {
                    width: 358px;
                    padding: 0 20px;
                    margin: 0;
                }
            }

            .slick-slide {
                transition: opacity 0.3s ease;
                opacity: 0.5;

                &.slick-current {
                    opacity: 1;
                }
            }
        }

        &__img {
            height: 233px;
            margin-bottom: 27px;
        }

        &__time {
            top: 217px;
            left: 20px;
        }
    }
}

@media(max-width: 480px) {
    .news {
        &__img {
            height: 164px;
        }

        &__time {
            top: 148px;
            left: 15px;
        }

        &__name {
            font-size: 12px;
            line-height: 160%;
        }

        &__slider {
            .news {
                &__item {
                    width: 260px;
                    padding: 0 15px;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .news {

        &__time {
            left: 12px;
        }

        &__slider {
            .news {
                &__item {
                    width: 254px;
                    padding: 0 12px;
                }
            }
        }
    }
}