.btn-small {
    display: flex;
    align-items: center;

    &__item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        border: 1px solid #BDBDBD;
        border-radius: 30px;
        min-width: 46px;
        color:  #8A8A8A;
        text-decoration: none;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #8A8A8A;
        margin-left: 36px;

        &:first-child {
            margin-left: 0;
        }

        &--site {
            .btn-small {
                &__icon {
                    display: none;
                }
            }
        }

        .svg-icon {
            display: block;

            use {
                fill: #8A8A8A;
            }
        }
    }
}

@media(max-width: 640px) {
    .btn-small {
        &__item {
            margin-left: 12px;

            &--site {
                span {
                    display: none;
                }

                .btn-small {
                    &__icon {
                        display: block;
                    }
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .btn-small {
        padding-left: 24px;
        flex: none;

    }
}