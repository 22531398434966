.slider-for {
    width: 684px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;

    &__item {
        width: 684px;
        height: 546px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .slick-arrow {
        position: absolute;
        border: 0;
        padding: 0;
        outline: 0;
        background-color: transparent;
        z-index: 5;
        bottom: 41px;
        cursor: pointer;

        &:hover {
            .svg-icon {
                use {
                    stroke: @green;
                }
            }
        }

        &.slick-prev {
            right: 88px;

            &::before {
                content: " ";
                position: absolute;
                left: -14px;
                top: -11px;
                background-image: url(../images/common/button-back.svg);
                width: 84px;
                height: 42px;
                pointer-events: none;
            }
        }

        &.slick-next {
            right: 45px;
            transform: rotate(180deg);
        }

        .svg-icon {
            display: block;
            use {
                stroke: #BDBDBD;
                fill: transparent;
            }
        }
    }
}


@media(max-width: 1279px) {
    .slider-for {
        width: 474px;

        &__item {
            width: 474px;
            height: 377px;
        }

        .slick-arrow {
            bottom: 30px;


            .svg-icon {
                width: 7px;
                height: auto;
            }

            &.slick-next {
                right: 38px;
            }

            &.slick-prev {
                right: 71px;

                &::before {
                    width: 69px;
                    height: 34px;
                    background-size: cover;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .slider-nav {
        display: none;
    }

    .slider-for {
        width: 470px;
        height: 470px;
        border: 0;
        position: relative;

        .slick-dots {
            position: absolute;
            bottom: -4px;
            left: 0;
            right: 0;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                list-style: none;
                margin: 0 2px;


                button {
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    padding: 0;
                    border: 0;
                    font-size: 0;
                    background-color: @grey-1;
                }

                &.slick-active {
                    button {
                        background-color: @green;
                    }
                }

            }
        }

        &__item {
            width: 470px;
            height: 470px;
        }
    }
}

@media(max-width: 640px) {
    .slider-for {
        height: calc(~"100vw - 60px");
        width: calc(~"100vw - 60px");
        
        &__item {
            height: calc(~"100vw - 60px");
            width: calc(~"100vw - 60px");
        }
    }
}

@media(max-width: 480px) {
    .slider-for {
        padding-bottom: 34px;
        height: calc(~"100vw - 48px");
        width: calc(~"100vw - 48px");
        
        &__item {
            height: calc(~"100vw - 48px");
            width: calc(~"100vw - 48px");
        }
    }
}