.select {
    &__title {
        margin-bottom: 12px;
    }

    .dropdown {
        .jq-selectbox__dropdown {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .jq-selectbox__select {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .dropup {

        .jq-selectbox__dropdown {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
           
        }

        .jq-selectbox__select {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    .jq-selectbox__select {
        height: 48px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        box-shadow: inset 0px 3px 10px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        position: relative;
        transition: border-radius 0.3s ease;
    }

    .jq-selectbox__trigger {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/common/arrow-select.png);
        transition: transform 0.3s ease;
    }

    .jq-selectbox__select-text {
        width: 100%!important;
        height: 100%;
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        padding: 0 50px 0 20px;
    }

    .jq-selectbox {
        width: 100%;

        &.opened {
            .jq-selectbox__trigger {
                transform: rotate(180deg);
            }
        }
    } 

    .jq-selectbox__dropdown {
        background-color: #fff;
        border-radius: 18px;
       
        box-shadow: 0 3px 10px rgba(0, 0, 0, 15%);
        width: 100%;
    }

    .jq-selectbox__dropdown ul {
        padding: 0;
        margin: 0;

        li {
            font-family: Montserrat;
            font-size: 18px;
            line-height: 150%;
            padding: 10px 20px;
        }
    }
}




@media(max-width: 1279px) {
    .select {
        &__title {
            font-size: 13px;
            line-height: 160%;
        }
    }
}

@media(max-width: 960px) {
    .select {
        .jq-selectbox__select-text {
            font-size: 14px;
            line-height: 150%;
        }

        .jq-selectbox__select {
            height: 45px;
        }

        .jq-selectbox__trigger {
            width: 45px;
            height: 45px;
        }

        .jq-selectbox__dropdown  {
            ul {
                li {
                    font-size: 14px;
                    line-height: 150%;
                    padding: 12px 20px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .select {
        &__title {
            margin-bottom: 8px;
        }
    }
}