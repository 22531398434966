.wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__container {
        padding-bottom: 82px;
        flex-grow: 1;

        &--pb0 {
            padding-bottom: 0;
        }
    }

    &__section {
        display: flex;

        &--bloki {
            display: block;
            overflow: hidden;
            padding-bottom: 85px;

            .wrapper {
                &__block {
                    width: calc(~"100% - 382px");
                    float: left;
                    padding-right: 103px;
                    
                }

                &__aside {
                    float: left;
                    margin-left: 0;
                }
            }
        }
    }

    &__aside {
        flex: none;
        width: 342px;
        margin-left: 103px;
    }

    &--index {
        .wrapper {
            &__container {
                padding-bottom: 0;
            }
        }
    }

    &--pb0 {
        .wrapper {
            &__container {
                padding-bottom: 0;
            }
        }
    }
}

@media(max-width: 1279px) {
    .wrapper {
        &__section {
           

            &--bloki {
                display: block;

                .wrapper {
                    &__aside {
                        display: none;
                    }

                    &__block {
                        width: 100%;
                        float: none;
                        padding-right: 0; 
                    }
                }
            }
        }
    }

}



@media(max-width: 960px) {
    .wrapper {
        &__section {
            &--bloki {
                .wrapper {
                    &__block {
                        padding-bottom: 70px;
                    }
                }
            }
        }
    }
}
