* {
    box-sizing: border-box;
}

.clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .modal-footer:after, .modal-footer:before, .row:after, .row:before {
    content: " ";
    display: table;
}

.clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .modal-footer:after, .row:after {
    clear: both;
}

html, body {
    height: 100%;
}

body  {
    height: auto;
    min-height: 100%;
}

input, a, button, .slick-slide, textarea {
    outline: 0;
}

img {
    max-width: 100%;
}

textarea {
    resize: none;
}

body {
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    color: #000;
    font-size: 15px;
    line-height: 160%;
    letter-spacing: -0.015em;
    overflow-x: hidden;

    &.is-grey {
        background-color: @grey-1;
    }

    &.is-hidden {
        overflow: hidden;
    }
}


h1, .h1 {
    font-weight: bold;
    font-size: 41px;
    line-height: 132%;
    letter-spacing: -0.05em;
}

h2 {
    font-weight: bold;
    font-size: 33px;
    line-height: 132%;
    letter-spacing: -0.05em;
}

h3 {
    font-weight: bold;
    font-size: 26px;
    line-height: 132%;
    letter-spacing: -0.05em;
    text-transform: uppercase;
}

h4 {
    font-weight: bold;
    font-size: 21px;
    line-height: 132%;
    text-transform: uppercase;
    
}

h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.05em;
    text-transform: uppercase;
}

@media(max-width: 1440px) {
    h2 {
        letter-spacing: inherit;
    }
}

@media(max-width: 1279px) {
    h1, .h1 {
        font-size: 31px;
        line-height: 132%;
        letter-spacing: initial;
    }


    h3 {
        font-size: 20px;
        letter-spacing: initial;
        line-height: 132%;
    }
}

@media(max-width: 640px) {
    h3 {
        font-size: 16px;
        line-height: 132%;
    }

    h1, .h1 {
        font-size: 24px;
        line-height: 132%;
    }
}

.date-block {
    font-size: 15px;
    line-height: 160%;
    color: #8A8A8A;
    margin-top: 11px;
}

.site {
    display: inline-flex;
    align-items: center;

    svg {
        display: block;
        margin-left: 7px;
        margin-top: 2px;
        border-bottom: 1px solid transparent;
        transition: border-color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .site {
        &:hover {
            svg {
                border-color: #fff;
            }
        }
    }
}

@media(max-width: 960px) {
    .site {
        svg {
            margin-top: 0;
        }
    }
}

.more-link {
    display: flex;
    justify-content: center;
    margin-top: 101px;

    a {
        color: @green;
        font-weight: bold;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        text-decoration: none;
        transition: border-color 0.3s ease;
        border-bottom: 2px solid transparent;
    }
}

@media(min-width: 960px) {
    .more-link {
        a {
            &:hover {
                border-color: @green;
            }
        }
    }
}

@media(max-width: 1279px) { 
    .more-link {
        margin-top: 65px;
    }

}

.container {
    width: 1224px;
}

@media(max-width: 1279px) {
    .container {
        width: 905px;
    }
}

@media(max-width: 960px) {
    .container {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media(max-width: 480px) {
    .container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

html {
    &.is-index {
        scroll-behavior: smooth;
    }
}

body {
    padding-top: 78px;

    &.is-index {
        padding-top: 0 !important;
    }
}

@media (max-width: 1440px) {
    body {
        padding-top: 60px;
    }
}

@media (max-width: 1279px) {
    body {
        padding-top: 54px;
    }
}

@media(max-width: 640px) {
    body {
        &.is-product {
            background-color: transparent;
        }
    }
}
