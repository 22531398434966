.article {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px -45px;

    &__icon {
        position: absolute;
        z-index: 1;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: hsla(0,0%,100%,.3);
        border-radius: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        padding-left: 5px;
    }

    &__slider {
        display: none;
    }

    &__container {
        width: calc(~"33.3% - 30px");
        margin: 0 15px 45px;
    }

    &__item {

        color: inherit;
        text-decoration: none;
    }

    &__img {
        position: relative;
        overflow: hidden;
        height: 181px;
        border-radius: 6px;
        background-position: center;
        background-size: cover;

        &::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000000;
            opacity: 0.1;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 17px;
        line-height: 145%;
        text-decoration: none;
        letter-spacing: -0.015em;
        margin-top: 15px;
        transition: color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .article {
        &__item {
            &:hover {
                .article {
                    &__text {
                        color: @green;
                    }
                }
            }
        }
    }
}

@media(max-width: 1279px) {
    .article {
        &__container {
            width: calc(~"50% - 30px");
        }


        &__text {
            font-size: 14px;
            line-height: 145%;
        }
    }
}

@media(max-width: 960px) {
    .article {
        margin: 0 -9px -36px;

        &__img {
            height: 32.7vw;
        }

        &__container {
            width: calc(~"50% - 18px");
            margin: 0 9px 36px;
        }
    }
}

@media(max-width: 640px) {
    .article {
        display: none;

        &__container {
            margin-bottom: 0;
            margin: 0;
        }

        &__img {
            height: 64vw;
        }

        &__slider {
            display: block;

            .slick-dots {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
    
                li {
                    margin: 0 2px;
                    padding: 0 !important;
                    list-style: none;
    
                    &::before {
                        display: none !important;
                    }
    
                    button {
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        padding: 0;
                        border: 0;
                        font-size: 0;
                        background-color: @grey-1;
                    }
    
                    &.slick-active {
                        button {
                            background-color: @green;
                        }
                    }
    
                }
            }
        }
    }
}