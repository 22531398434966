.up {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: fixed;
    padding: 8px 17px;
    background-color: @green;
    right: 20px;
    bottom: 20px;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    border-radius: 15px;
    transition: opacity 0.3s ease;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    svg {
        margin-top: -1px;
    }

    span {
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-right: 5px;
    }
}

@media(max-width: 640px) {
    .up {
        right: 25px;
        bottom: 25px;
        padding: 5px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        span {
            display: none;
        }
    }
}