.menu-bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 61px;

    a {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        text-decoration: none;
        letter-spacing: 0.03em;
        color: #fff;
        margin-right: 24px;
        margin-bottom: 11px;
        transition: color 0.3s ease;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media(min-width: 960px) {
    .menu-bottom {
        a {
            &:hover {
                color: @green;
            }
        }
    }
}

@media(max-width: 1279px) {
    .menu-bottom {
        a {
            font-size: 12px;
            line-height: 132%;
        }
    }
}

@media(max-width: 960px) { 
    .menu-bottom {
        display: none;
    }
}