.schema {
    width: 1490px;
    margin: 0 auto;

    &__img {
        svg {
            overflow: visible;
        }

    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        background-color: @green;
        border-radius: 50%;
        margin-right: 10px;
        flex: none;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &__name {
        font-weight: 500;
        font-size: 14px;
        line-height: 132%;
        color: #fff;
        text-decoration: underline;
        letter-spacing: -0.01em;
        transition: color 0.3s ease;
    }

    &__line {
        stroke: #fff;
        transition: stroke 0.3s ease;
        z-index: 2;
        position: relative;

        &.is-active {
            stroke: @green;
        }
    }

    &__fill {
        fill: #fff;
        transition: fill 0.3s ease;
        z-index: 2;
        position: relative;

        &.is-active {
            fill: @green;
        }
    }

    &__container {
        position: relative;
    }

    &__pic {
        position: absolute;
        cursor: pointer;

        img {
            width: auto;
            max-width: initial;
        }

        .hover {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            left: -35px;
            top: -33px;
            transition: opacity 0.3s ease;
        }

        &:hover {
            .hover {
                opacity: 1;
            }
        }
        
        &.is-active {
            .hover {
                opacity: 1;
            }
        }

        &--sprayer {
            top: 14.2%;
            left: 12.58%;
        }

        &--applicator {
            top: 28.1%;
            left: 12.58%;
        }

        &--seeders {
            top: 42.1%;
            left: 12.58%;
        }

        &--mobile {
            top: 26.9%;
            left: 38.8%;
        }

        &--management {
            top: 50%;
            left: 34.95%;
        }

        &--antenna {
            top: 19.3%;
            left: 59.6%;
        }

        &--steering {
            top: 76.8%;
            left: 48.2%;
        }

        &--main {
            top: 49.1%;
            left: 65.8%;
        }
        
    }

    &__item {
        position: absolute;
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        &:hover {
            .schema {
                &__name {
                    color: @green;
                }
            }
        }

        &.is-active {
            .schema {
                &__name {
                    color: @green;
                }
            }
        }

        &--sprayer {
            top: 17.4%;
            left: 23.8%;
        }

        &--applicator {
            top: 31.3%;
            left: 23.8%;
        }

        &--seeders {
            top: 45.3%;
            left: 23.8%;
        }

        &--mobile {
            top: 40.1%;
            left: 44.8%;
        }

        &--antenna {
            top: 18.3%;
            left: 53.6%;
        }

        &--management {
            top: 68.1%;
            left: 39%;
        }

        &--steering {
            top: 78.1%;
            left: 39%;
        }

        &--main {
            top: 45.4%;
            left: 77.5%;
        }
    }
}

@media(max-width: 640px) {
    .schema {
        display: none;
    }
}

@media(max-width: 1520px) {
    .schema {
        width: auto;

        &__img {
            width: 1197px;
            margin: 0 auto;

            svg {
                width: 100%;
                height: auto;
            }
        }

        &__icon {
            width: 20px;
            height: 20px;
        }

        &__name {
            font-size: 11.5px;
            line-height: 132%;
        }

        &__pic {
            img {
                max-width: 100%;
            }

            .hover {
                max-width: initial;
                width: 179px;
                left: -28px;
                top: -27px;
            }

            &--sprayer {
                width: 122px;
                left: 19.9%;
                top: 14.2%;
            }
            
            &--applicator {
                width: 122px;
                left: 19.9%;
                top: 28.1%;
            }

            &--main {
                width: 161px;
                left: 62.8%;

                .hover {
                    width: 217px;
                }
            }

            &--steering {
                width: 213px;
                top: 76.3%;
                left: 48.7%;

                .hover {
                    width: 269px;
                    left: -28px;
                    top: -26px;
                }
            }

            &--applicator {
                width: 122px;
                left: 19.9%;
                top: 28.1%;
            }

            &--seeders {
                width: 122px;
                left: 19.9%;
                top: 42.1%;
            }

            &--mobile {
                width: 155px;
                left: 40.9%;
                top: 26.8%;

                .hover {
                    width: 211px;
                    left: -29px;
                    top: -26px;
                }
            }

            &--antenna {
                width: 165px;
                left: 57.6%;
                top: 19%;

                .hover {
                    width: 222px;
                }
            }

            &--management {
                width: 223px;
                top: 50%;
                left: 37.75%;

                .hover {
                    width: 280px;
                    left: -28px;
                    top: -27px;
                }
            }
        }

        &__item {
            &--sprayer {
                left: 28.8%;
            }

            &--applicator {
                left: 28.8%;
            }

            &--seeders {
                left: 28.8%;
            }

            &--mobile {
                left: 45.8%;
            }

            &--antenna {
                left: 52.8%;
            }

            &--management {
                left: 41%;
            }

            &--steering {
                left: 41%;
            }

            &--main {
                left: 71.5%;
            }
        }
    }
}

@media(max-width: 1279px) {
    .schema {
        width: auto;

        &__img {
            width: 876px;
        }

        &__item {
            &--sprayer {
                left: 32%;
            }

            &--applicator {
                left: 32%;
            }

            &--seeders {
                left: 32%;
            }

            &--mobile {
                left: 46.5%;
            }

            &--antenna {
                left: 52.4%;
            }

            &--management {
                left: 42.5%;
            }

            &--main {
                left: 68.8%;
            }

            &--steering {
                left: 42.5%;
            }
        }

        &__pic {
            .hover {
                width: 132px;
                left: -21px;
                top: -20px;
            }

            &--sprayer {
                width: 90px;
                left: 24.3%;            
            }

            &--applicator {
                width: 90px;
                left: 24.3%;  
            }

            &--seeders {
                width: 90px;
                left: 24.3%;  
            }

            &--steering {
                width: 158px;

                .hover {
                    width: 200px;
                }
            }

            &--main {
                width: 118px;
                left: 60.8%;

                .hover {
                    width: 160px;
                }
            }

            &--management {
                width: 164px;
                left: 39.6%;

                .hover {
                    width: 206px;
                }
            }

            &--antenna {
                width: 122px;
                left: 56.6%;

                .hover {
                    width: 163px;
                }
            }

            &--mobile {
                width: 115px;
                left: 42.2%;
                top: 26.7%;

                .hover {
                    width: 154px;
                    left: -19px;
                    top: -19px;
                }

            }
        }

        &__icon {
            width: 15px;
            height: 16px;
            margin-right: 5px;

            svg {
                width: 7px;
                height: auto;
            }
        }

        &__name {
            font-size: 8.5px;
        }
    }
}

@media(max-width: 960px) {
    .schema {
        &__img {
            width: 580px;
        }

        &__name {
            font-size: 5.5px;
            text-decoration: none;
        }

        &__icon {
            width: 11px;
            height: 11px;
            margin-right: 4px;

            svg {
                width: 5px;
            }
        }

        &__item {
            &--sprayer {
                left: 34%;
            }

            &--applicator {
                left: 34%;
            }

            &--seeders {
                left: 34%;
            }

            &--mobile {
                left: 46.8%;
            }

            &--management {
                left: 43.1%;
            }

            &--steering {
                left: 43.1%;
            }

            &--main {
                left: 66.3%;
            }
        }

        &__pic {
            .hover {
                width: 87px;
                left: -15px;
                top: -13px;
            }  

            &--sprayer {
                width: 58px;
                left: 27.3%;
            }

            &--applicator {
                width: 58px;
                left: 27.3%;
            }

            &--seeders {
                width: 58px;
                left: 27.3%;
            }

            &--mobile {
                width: 76px;
                left: 43.2%;
                top: 26.7%;

                .hover {
                    width: 104px;
                }
            }

            &--antenna {
                width: 80px;
                left: 55.6%;

                .hover {
                    width: 109px;
                }
            }

            &--management {
                width: 110px;
                left: 40.6%;

                .hover {
                    width: 139px;
                }
            }

            &--steering {
                width: 102px;

                .hover {
                    width: 131px;
                    left: -13px;
                    top: -14px;
                }
            }

            &--main {
                width: 76px;
                left: 59.8%;

                .hover {
                    width: 105px;
                    left: -16px;
                    top: -14px;
                }
            }
        }
    }
}