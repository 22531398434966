.tech {
    margin-bottom: 42px;

    &:last-child {
        margin-bottom: 0;
    }

    div {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        span {
            width: 160px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__table {
        margin: 0 -2px 42px;
        width: 580px;

        table {
            width: 100%;

            td {
                font-size: 17px;
                line-height: 160%;
                word-break: break-all;
                padding: 7px 11px;
                background-color: @grey-1;
            }

            tr {
                td {
                    &:nth-child(2) {                  
                        width: 95px;
                    }
                }

                &:first-child {
                    td {
                        &:last-child {
                            width: 270px;
                        }

                        &:first-child {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}


@media(max-width: 1279px) {
    .tech {
        div {
            font-size: 14px;
            line-height: 160%;
        }

        &__table {
            tr {
                td {
                    font-size: 14px;
                    line-height: 160%;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .tech {
        div {
            margin-bottom: 8px;
        }
    }
}

@media(max-width: 640px) {
    .tech {
        &__table {
            margin-top: 18px;

            table {
                tr {
                    &:first-child {
                        td {
                            &:last-child {
                                width: 62%;
                            }
                        }
                    }

                    td {
                        &:nth-child(2) {
                            width: 66px;
                        }
                    }
                }
            }
        }
    }
}