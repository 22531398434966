.main-block {
    background: linear-gradient(180deg, #183170 -11.49%, #0F1932 24.51%);
    color: #fff;
    position: relative;

    &__star {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 49px;
        pointer-events: none;
    }

    &:nth-child(1) {
        padding-top: 178px;
        z-index: 2;
    }

    &--planet {
        padding-top: 173px;
        background: linear-gradient(180deg, #0F1932 32.29%, #183170 76.56%);
        position: relative;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 410px;
            background: linear-gradient(360deg, #0F1932 0%, rgba(15, 25, 50, 0) 100%);
            pointer-events: none;
        }
    }


    &__item {
        padding-bottom: 155px;

        &:last-child {
            padding-bottom: 0;
        }

        h1 {
            margin-top: 0;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 41px;
            padding-left: 24px;
            padding-right: 24px;

            span {
                display: block;
                color: @green;
            }
        }

        .section {
            &__text {
                max-width: 785px;
                font-weight: normal;
            }
        }
    }
}

@media(max-width: 1279px) {
    .main-block {
        &--planet {
            padding-top: 137px;

            .section {
                &__top {
                    h3 {
                        margin-bottom: 43px;
                    }
                }
            }
        }

        &__item {
            .section {
                &__text {
                    max-width: 92%;
                }
            }
        }
    }
}



@media(max-width: 1440px) {
    .main-block {
        &__item {
            h1 {
                font-size: 31px;
                line-height: 132%;
                margin-bottom: 47px;
            }
        }
    }
}

@media(max-width: 960px) {
    .main-block {

        &--planet {
            padding-top: 101px;

            .section {
                &__top {
                    margin-bottom: 42px;

                    h3 {
                        margin-bottom: 29px;
                    }
                }

                &__text {
                    max-width: initial;
                }

                &__btn {
                    margin-top: 19px;
                }
            }

            h3 {
                font-size: 24px;
                line-height: 132%;
            }
        }

        &__item {
            h1 {
                font-size: 25px;
                line-height: 132%;
                margin-bottom: 48px;
            }
        }
    }
}

@media(max-width: 640px) {
    .main-block {

        &__star {
            display: none;
        }
        
        &:first-child {
            padding-top: 100px;
        }

        &--planet {
            &::before {
                height: 306px;
                z-index: 1;
            }

            .section {
                &__btn {
                    margin-top: 28px;
                }
            }
        }

        &__item {
            padding-bottom: 101px;

            h1 {
                font-size: 19px;
                line-height: 132%;
                margin-bottom: 35px;
            }
        }
    }
}


@media(max-width: 480px) {
    .main-block {
        &--planet {
            &::before {
                height: 175px;
            }
        }
    }
}

@media(max-width: 360px) {
    .main-block {
        &__item {
            h1 {
                margin-bottom: 10px;
            }
        }
    }
}
