.detail-page {
    overflow: hidden;
    color: #000000;

    .doc {
        margin-top: 30px;
        
        &__item {
            img {
                border-radius: 0;
                margin-bottom: 0;
            }
        }
    }

    img {
        margin-bottom: 44px;
        border-radius: 10px;
    }

    &__container {
        float: left;
        width: calc(~"100% - 342px");
        padding-right: 66px;
    }

    &__aside {
        float: left;
        width: 342px;

        &-title {
            font-weight: bold;
            font-size: 17px;
            line-height: 160%;
            letter-spacing: -0.015em;
            margin-bottom: 30px;
            color: #000000;
        }
    }

    p {
        font-size: 17px;
        line-height: 160%;
        margin: 0 0 10px;
        letter-spacing: -0.015em;
    }

    h4 {
        margin: 49px 0 25px;
        letter-spacing: initial;
    }

    h3 {
        letter-spacing: initial;
        margin-bottom: 38px;
        margin-top: 48px;
    }

    h5 {
        margin: 47px 0 25px;
        letter-spacing: initial;
    }

    ul {
        padding: 0;
        margin: 20px 0;

        li {
            list-style: none;
            position: relative;
            font-size: 17px;
            line-height: 160%;
            margin: 0 0 10px;
            padding-left: 24px;
            margin-bottom: 12px;

            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: @green;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
    }

    ol {
        margin: 24px 0;
        counter-reset: myCounter;
        list-style: none;
        padding: 0;
        margin: 0;
        letter-spacing: -0.015em;


        li {
            position: relative;
            font-size: 17px;
            line-height: 160%;
            margin: 0 0 10px;
            padding-left: 24px;
            margin-bottom: 12px;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                color: @green;
                counter-increment: myCounter;
                content:counter(myCounter);
            }
        }
    }

    &__section {
        margin-top: 48px;

        h3 {
            margin-top: 0;    
        }

        h5 {
            margin-top: 0;
        }
    }
}

@media(max-width: 1280px) {
    .is-card {
        .date-block {
            display: none;
        }
    }

    .detail-page {
       

        &__aside {
            width: 240px;

            .news-small {
                &__item {
                    width: calc(~'100% - 30px');
                }
            }
        }

        h3 {
            margin-bottom: 24px;
        }

        .pic {
            &__name {
                font-size: 13px;
                line-height: 160%;
            }

            &__date {
                font-size: 13px;
                line-height: 160%;
            }
        }

        h4 {
            font-size: 17px;
            line-height: 132%;
        }

        ol {
            li {
                font-size: 14px;
                line-height: 160%;
            }
        }

        ul {
            li {
                font-size: 14px;
                line-height: 160%;
            }
        }

        p {
            font-size: 14px;
            line-height: 160%;
        }

        &__container {
            width: calc(~'100% - 248px');
            padding-right: 62px;
        }
    }
}

@media(max-width: 960px) {
    .detail-page {
        overflow: visible;

        img {
            width: 100%;
        }

        &__container {
            width: 100%;
            padding-right: 0;
            padding-bottom: 60px;
            float: none;
        }

        &__aside {
            width: calc(~'100% + 60px');
            background-color: #f3f3f3;
            margin: 0 -30px -70px;
            float: none;
            position: relative;
            z-index: 2;
            padding: 48px 30px 50px;

            &-title {
                display: none;
            }

            .news-small {
                flex-direction: row;

                &__item {
                    width: calc(~"50% - 30px");
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .detail-page {
        &__aside {
            .news-small {
                &__item {
                    width: calc(~"100% - 30px");
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .detail-page {
        &__aside {
            margin-bottom: -60px;
            padding-top: 36px;
            padding-bottom: 36px;
            width: calc(~'100% + 48px');
            margin-left: -24px;
            margin-right: -24px;
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}